import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../../utils/useAuth";

const SwitchAccount: React.FC = () => {
  const { switchAccount } = useAuth();
  const navigate = useNavigate();

  const doLogout = async () => {
    switchAccount(null);
    navigate("/");
  };

  useEffect(() => {
    doLogout();
  }, []); // eslint-disable-line

  return <div />;
};

export default SwitchAccount;
