import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../../utils/useAuth";

const Logout: React.FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const doLogout = async () => {
    try {
      await logout();
    } catch (error) {}
    navigate("/");
  };

  useEffect(() => {
    doLogout();
  }, []); // eslint-disable-line

  return <div />;
};

export default Logout;
