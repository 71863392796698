import EditManufacturerDetailsForm from "../Forms/EditManufacturerDetailsForm";

const SettingsTabDetails: React.FC = () => {
  return (
    <div>
      <EditManufacturerDetailsForm />
    </div>
  );
};

export default SettingsTabDetails;
