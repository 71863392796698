import MainSidebar from "../../Sidebars/MainSidebar";

interface DashboardLayoutProps {
  active?: string;
}
const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  active = "",
}) => {
  return (
    <div className="flex w-full justify-between bg-gray-50 h-screen overflow-hidden">
      <MainSidebar active={active} />
      {children}
    </div>
  );
};

export default DashboardLayout;
