import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultButton from "../Buttons/Default";
import FieldHolder from "../Inputs/FieldHolder";
import AsyncSelectInput from "../Inputs/Select/AsyncSelect";
import TextInput from "../Inputs/TextInput";
import { SingleModalProps } from "../Modal";
import { errorParser } from "../Form/Form.utils";

const AddProductModal: React.FC<SingleModalProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    watch,
  } = useForm<any>({
    defaultValues: {
      details: "",
      mpn: null,
    },
  });

  const mpn = watch("mpn");

  const onSubmit = async (formData: any) => {
    setLoading(true);

    const productItem = Number.isNaN(formData.mpn.value)
      ? {
          serial_number: formData.serial_number,
          details: formData.details,
        }
      : {
          product_id: Number.isNaN(formData.mpn.value)
            ? null
            : formData.mpn.value,
          serial_number: formData.serial_number,
        };

    try {
      const { data } = await axios.post("manufacturers/items", productItem);
      if (data && data?.data?.id) {
        navigate(`/products/${data.data.id}`);
      } else {
        toast(
          "Product Request submitted, you will be notified when its been approved",
          {
            position: "bottom-center",
            type: "success",
            theme: "dark",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }

      onClose();
    } catch (error) {
      errorParser(error, setError);
    }

    setLoading(false);
  };

  const doSearch = async (term: string, callback: (options: []) => void) => {
    if (term.length < 2) return;

    try {
      const {
        data: { data },
      } = await axios.get("/products", {
        params: {
          query: searchTerm,
        },
      });

      callback(data.map((pr: any) => ({ value: pr.id, label: pr.name })));
      //   history.push("/");
    } catch (error) {
      callback([]);
    }
  };

  useEffect(() => {
    setValue("details", "");
  }, [mpn]); // eslint-disable-line

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-5">
        <h3 className="text-2xl font-medium text-gray-800 mb-2">
          Add a new product
        </h3>
        <p className="text-gray-600 text-sm">
          RemadeM arket verifies all system products manually, please search for
          your product code bellow, if you don&lsquo;t find an exact match you
          can add one and that will be sent to the product review team.
        </p>
      </div>
      <FieldHolder
        {...{
          control,
          errors,
          label: "MPN Search",
          id: "mpn",
          required: true,
        }}
        field={({ field: { onChange, value } }: any): JSX.Element => (
          <AsyncSelectInput
            onChange={onChange}
            value={value}
            isMulti={false}
            placeholder="Start typing to search products"
            creatable
            selectProps={{
              cacheOptions: true,
              defaultOptions: [],
              clearable: true,
              loadOptions: doSearch,
              onInputChange: (val: string) => setSearchTerm(val),
            }}
          />
        )}
      />

      {mpn && (
        <>
          {Number.isNaN(mpn.value) ? (
            <FieldHolder
              {...{
                control,
                errors,
                label: "Product Details",
                id: "details",
                required: true,
              }}
              field={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.details}
                  placeholder="Enter product details, include as much detail as possible"
                  type="textarea"
                />
              )}
            />
          ) : (
            <></>
          )}

          <FieldHolder
            {...{
              control,
              errors,
              label: "Serial Number",
              id: "serial_number",
              required: true,
            }}
            field={({ field: { onChange, value } }: any): JSX.Element => (
              <TextInput
                value={value}
                onChange={onChange}
                invalid={!!errors.details}
                placeholder="Enter product serial number"
              />
            )}
          />
        </>
      )}

      <div className="flex items-center justify-between mt-8 w-full">
        <DefaultButton
          title={loading ? "Loading..." : "Create"}
          type="submit"
        />
      </div>
    </form>
  );
};

export default AddProductModal;
