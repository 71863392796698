import axios from "axios";
import cookie from "cookie";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

axios.defaults.headers.common = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

axios.interceptors.request.use(
  (config) => {
    const newConfig = config;
    if (cookie.parse(document.cookie)["XSRF-TOKEN"]) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newConfig.headers!["X-XSRF-TOKEN"] = cookie.parse(document.cookie)[
        "XSRF-TOKEN"
      ];
    }

    return newConfig;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// axios.interceptors.response.use(
//   (response: AxiosResponse<any>) => {
//     return { data: response.data, headers: response.headers };
//   },
//   (err) => {
//     // if (err.response.status === 401) {
//     //   window.localStorage.clear();
//     //   window.location = "/";
//     //   return;
//     // }

//     // if (err.response.status === 503) {
//     //   window.location = "/system-maintenance";
//     //   return;
//     // }

//     return Promise.reject(err);
//   }
// );

export default axios;
