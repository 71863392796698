import axios from "axios";
import React, { useEffect, useState } from "react";
import DropdownButton from "../DropdownButton";
import DashboardCounts from "./Counts";
import MonthlyDashboardGraph from "./Graphs/Monthly";
import WeeklyDashboardGraph from "./Graphs/Weekly";
import YearlyDashboardGraph from "./Graphs/Yearly";

export type DashViewType = "this_week" | "this_month" | "this_year";
const ManufacturerDashboard: React.FC = () => {
  const [loaded, setLoaded] = useState(false);
  const [errored, setErrored] = useState(false);
  const [dashData, setDashData] = useState<any>({});

  const [currentView, setCurrentView] = useState<DashViewType>("this_week");

  const options = {
    this_week: "This Week",
    this_month: "This Month",
    this_year: "This Year",
  };

  const getDashboardData = async () => {
    try {
      const { data } = await axios.get("manufacturers/reports");
      setDashData(data);
    } catch (error) {
      setErrored(true);
    }
    setLoaded(true);
  };
  useEffect(() => {
    getDashboardData();
  }, []);

  if (!loaded) return <div>Loading....</div>;
  if (errored) return <div>Failed to load reports, please contact support</div>;

  return (
    <div>
      <>
        <div className="flex items-center justify-between">
          <h1 className="text-4xl font-medium">Dashboard</h1>
          <div className="w-56">
            <DropdownButton
              title={options[currentView]}
              items={Object.keys(options).map((optK: string) => ({
                body: (
                  <div className="p-3 w-56 flex items-center cursor-pointer hover:bg-gray-100">
                    {/* @ts-expect-error self explanatory */}
                    {options[optK]}
                  </div>
                ),
                onClick: () => {
                  setCurrentView(optK as DashViewType);
                },
              }))}
            />
          </div>
        </div>
        <DashboardCounts data={dashData} currentView={currentView} />
        <div className="my-6 bg-white rounded-xl p-4 shadow-lg">
          <h3 className="font-medium text-xl">Revenue</h3>
          {currentView === "this_week" && (
            <WeeklyDashboardGraph data={dashData.weekly.graph} />
          )}
          {currentView === "this_month" && (
            <MonthlyDashboardGraph data={dashData.monthly.graph} />
          )}
          {currentView === "this_year" && (
            <YearlyDashboardGraph data={dashData.yearly.graph} />
          )}
        </div>
      </>
    </div>
  );
};

export default ManufacturerDashboard;
