import { useEffect, useState } from "react";
import GhostCertificationField from "../../Inputs/GhostCertificationField";
import TextInput from "../../Inputs/TextInput";
import RegisterStepTitle from "../RegisterStepTitle";
import RegistrationFieldHolder from "../RegistrationFieldHolder";

const Certifications: React.FC<any> = ({
  control,
  errors,
  getValues,
  industries,
  watch,
}) => {
  const [industriesToUse, setIndustriesToUse] = useState([]);

  // const selectedIndustries = watch("industries", []);

  useEffect(() => {
    if (typeof getValues("industries", []) === "object") {
      const ids = getValues("industries", []).map((ind: any) => ind.value);
      setIndustriesToUse(industries.filter((ind: any) => ids.includes(ind.id)));
    }
  }, []); // eslint-disable-line

  return (
    <div className="">
      <RegisterStepTitle
        title="Certifications &amp; Quality Assurance"
        subtitle="For quality assurance purposes we required detailed info on your companies accreditation and practices within each industry. Fields marked with * are mandatory."
      />

      <RegistrationFieldHolder
        {...{
          control,
          errors,
          label: "General Accreditation",
          id: "certifications.general_accreditation",
          required: true,
        }}
        field={({ field: { onChange, value } }: any): JSX.Element => (
          <TextInput
            value={value}
            onChange={onChange}
            invalid={!!errors.general_accreditation}
            placeholder="ISO9001"
          />
        )}
      />

      {industriesToUse.map((indus: any) => (
        <div className="my-6 border-b pb-8 mb-2">
          <h3 className="font-medium text-xl text-gray-500">{indus.name}</h3>
          <p className="text-sm mb-6 text-gray-600">
            Please fill out the following industry specific questions.
          </p>
          {indus.registration_rules.map((field: any) => (
            <div className="my-6">
              <RegistrationFieldHolder
                {...{
                  control,
                  errors,
                  label: field.label,
                  id: `certifications.${indus.slug}.${field.id}`,
                  required: true,
                }}
                field={({ field: { onChange, value } }: any): JSX.Element => (
                  <GhostCertificationField
                    value={value}
                    onChange={onChange}
                    invalid={
                      errors.certifications &&
                      errors.certifications[indus.slug] &&
                      !!errors[indus.slug][field.id]
                    }
                    placeholder={field.placeholder ?? ""}
                    field={field}
                  />
                )}
              />
            </div>
          ))}
        </div>
      ))}
      {/* <RegistrationFieldHolder
        {...{
          control,
          errors,
          label: "Choose industries that apply.",
          id: "industries",
          required: true,
        }}
        field={({ field: { onChange, value } }: any): JSX.Element => (
          <SelectInput
            value={value}
            onChange={onChange}
            invalid={!!errors.postcode}
            placeholder="Choose Industries"
            options={industries.map((ind: any) => ({
              label: ind.name,
              value: ind.id,
            }))}
            selectProps={{}}
          />
        )}
      /> */}
    </div>
  );
};

export default Certifications;
