const AuthLayout: React.FC<any> = ({ children, width = "4/12" }) => {
  return (
    <div className="flex w-full h-screen bg-green-500">
      <div
        className={`w-${width} rounded-r bg-gray-50 shadow h-full flex-shrink-0 flex-grow-0`}
      >
        {children}
      </div>
      <div
        className="w-full bg-cover"
        style={{ backgroundImage: "url(./images/login-bg.png)" }}
      >
        <div className="bg-green-600 w-full h-full bg-opacity-80 p-20 flex items-center justify-start text-white">
          <div className="max-w-xl text-lg">
            <h2 className="text-3xl font-medium mb-4">
              Bringing Remanufacturing to the Mainstream
            </h2>
            <p className="font-normal my-4">
              Remade Group are on a mission to support Remanufacturers grow
              their business outreach by:
            </p>
            <ul className="font-medium list-disc ml-4">
              <li className="my-2">
                Direct Contact to Core Providers with Regular Stock
              </li>
              <li className="my-2">
                Increased Customer Confidence with Blockchain Technology
              </li>
              <li className="my-2">
                Wider Customer Exposure and Greater Outreach
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
