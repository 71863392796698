import TextInput from "../../Inputs/TextInput";

import RegisterStepTitle from "../RegisterStepTitle";
import RegistrationFieldHolder from "../RegistrationFieldHolder";

const Location: React.FC<any> = ({ register, control, errors }) => {
  return (
    <div>
      <RegisterStepTitle
        title="Location"
        subtitle="Please complete the following details to let us know your address. Fields marked with * are mandatory."
      />

      {/* <div onClick={() => setShowAddress(!showAddress)} className="text-xs text-blue-900 font-medium mt-4 cursor-pointer hover:text-blue-600">
        or Enter address manually
      </div> */}
      <RegistrationFieldHolder
        {...{
          control,
          errors,
          label: "Building Name / No",
          id: "address_building_name",
          required: true,
        }}
        field={({ field: { onChange, value } }: any): JSX.Element => (
          <TextInput
            value={value}
            onChange={onChange}
            invalid={!!errors.address_building_name}
            placeholder="123"
          />
        )}
      />

      <>
        <br />
        <div className="grid grid-flow-col grid-cols-2 gap-8 mb-6">
          <RegistrationFieldHolder
            {...{
              control,
              errors,
              label: "Street",
              id: "address_street",
              required: true,
            }}
            field={({ field: { onChange, value } }: any): JSX.Element => (
              <TextInput
                value={value}
                onChange={onChange}
                invalid={!!errors.address_street}
                placeholder="High Street"
              />
            )}
          />
          <RegistrationFieldHolder
            {...{
              control,
              errors,
              label: "City/Town",
              id: "address_city_town",
              required: true,
            }}
            field={({ field: { onChange, value } }: any): JSX.Element => (
              <TextInput
                value={value}
                onChange={onChange}
                invalid={!!errors.address_city_town}
                placeholder="Belfast"
              />
            )}
          />
        </div>
        <div className="grid grid-flow-col grid-cols-2 gap-8 mb-6">
          <RegistrationFieldHolder
            {...{
              control,
              errors,
              label: "County",
              id: "address_county",
              required: true,
            }}
            field={({ field: { onChange, value } }: any): JSX.Element => (
              <TextInput
                value={value}
                onChange={onChange}
                invalid={!!errors.address_county}
                placeholder="County Down"
              />
            )}
          />

          <RegistrationFieldHolder
            {...{
              control,
              errors,
              label: "Postcode",
              id: "address_postcode",
              required: true,
            }}
            field={({ field: { onChange, value } }: any): JSX.Element => (
              <TextInput
                value={value}
                onChange={onChange}
                invalid={!!errors.address_postcode}
                placeholder="BT5 6JR"
              />
            )}
          />
        </div>
      </>
    </div>
  );
};

export default Location;
