/* eslint-disable @typescript-eslint/no-explicit-any */
import { Control, Controller } from "react-hook-form";
import { errorStyle, labelStyle } from "../../constants/styles";

interface RegistrationFieldHolderProps {
  field: (props: any) => JSX.Element;
  control: Control<any>;
  errors: any;
  label?: string;
  id: string;
  required?: boolean;
}

// eslint-disable-next-line
export const _get = (object: Object, path: any, defval = null) => {
  // eslint-disable-next-line
  if (typeof path === "string") path = path.split(".");
  return path.reduce(
    (xs: any, x: any) => (xs && xs[x] ? xs[x] : defval),
    object
  );
};

const RegistrationFieldHolder: React.FC<RegistrationFieldHolderProps> = ({
  control,
  errors,
  label,
  id,
  field,
  required = false,
}) => {
  const errorsObj = _get(errors, id, null);
  return (
    <div className="mb-4">
      <label htmlFor={id} className={labelStyle}>
        {label}
        {required && <span className="ml-1 text-gray-400 font-sm">*</span>}
      </label>

      <div className="mt-4 rounded-md shadow-sm">
        <Controller
          name={id}
          control={control}
          defaultValue=""
          rules={{
            required,
          }}
          render={field}
        />
      </div>
      {errorsObj && errorsObj.type === "required" && (
        <span className={errorStyle}>{label} is required</span>
      )}
      {errorsObj && errorsObj.type === "api" && (
        <span className={errorStyle}>{errorsObj.message}</span>
      )}
    </div>
  );
};

export default RegistrationFieldHolder;
