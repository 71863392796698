import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import { GoUnverified, GoVerified } from "react-icons/go";
import axios from "../../../plugins/axios";

type BadgeColorType = "green" | "orange" | "transparent";
interface SetupStep {
  id: string;
  title: string;
  description: string;
  link: string;
  required: boolean;
  badge: null | {
    color: BadgeColorType;
    text: string;
  };
}

const DashboardSetupComponent: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [setupSteps, setSetupSteps] = useState<SetupStep[]>([]);

  const getSetupSteps = async () => {
    try {
      const { data } = await axios.get("/manufacturers/setup");
      setSetupSteps(data);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getSetupSteps();
  }, []);

  const renderBadge = (color: BadgeColorType, text: string, link: string) => {
    switch (color) {
      case "green":
        return (
          <Link
            to={link}
            className="px-6 py-2 bg-green-600 text-white rounded-full shadow-md text-xs flex items-center justify-between h-10 "
          >
            {text} <GoVerified size={20} className="ml-3" />
          </Link>
        );
      case "orange":
        return (
          <Link
            to={link}
            className="px-6 py-2 bg-red-400 text-white rounded-full shadow-md text-xs h-10 flex items-center justify-between "
          >
            {text} <GoUnverified size={20} className="ml-3" />
          </Link>
        );
      case "transparent":
        return (
          <Link
            to={link}
            className="px-6 py-2 border border-gray-700 rounded-full text-xs text-gray-700 hover:border-black hover:text-black flex items-center justify-between h-10 group"
          >
            {text}{" "}
            <IoIosArrowRoundForward
              size={25}
              className="ml-3 group-hover:scale-1"
            />
          </Link>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <div className="flex items-center justify-between mb-10">
        <h1 className="text-4xl font-medium">Complete Setup</h1>
      </div>
      {loading && <div>Loading...</div>}

      {setupSteps.map((step) => (
        <div
          key={step.id}
          className="bg-white rounded-lg shadow-xl mt-6 p-8 flex items-center justify-between"
        >
          <div className="w-2/3 flex-shrink-0 flex-grow-0">
            <h3 className="text-2xl font-medium mb-1">{step.title}</h3>
            <p className="text-gray-600">{step.description}</p>
          </div>
          <div>
            {step.badge != null &&
              renderBadge(step.badge.color, step.badge.text, step.link)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardSetupComponent;
