import React, { useEffect, useState } from "react";
import { ProductItem, ProductItemAction } from "../../types";
import axios from "../../plugins/axios";
import AuditItem from "./AuditItem";

interface ProductItemAuditLogProps {
  item: ProductItem;
}

const ProductItemAuditLog: React.FC<ProductItemAuditLogProps> = ({ item }) => {
  const [actions, setActions] = useState<ProductItemAction[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getActions();
  }, [item]); // eslint-disable-line

  const getActions = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await axios.get(`manufacturers/items/${item.id}/actions`);
      setActions(data as ProductItemAction[]);
    } catch (error) {}
    setLoading(false);
  };
  return (
    <div>
      <h3 className="mb-4 text-xl font-medium">Product History</h3>
      {loading && <div>Loading...</div>}
      {!loading && (
        <div>
          {actions.map((action, ind) => (
            <AuditItem {...action} key={ind} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductItemAuditLog;
