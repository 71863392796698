import axios from "axios";
import React, { useEffect, useState } from "react";

interface PaymentDetailsManagementProps {}

const PaymentDetailsManagement: React.FC<
  PaymentDetailsManagementProps
> = () => {
  const [resp, setResp] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPaymentSettings();
  }, []);

  const getPaymentSettings = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await axios.get("/manufacturers/settings/payments");
      setResp(data);
    } catch (error) {}
    setLoading(false);
  };

  if (loading) return <div>Loading...</div>;
  if (!resp)
    return (
      <div>
        Failed to load data from payment provider, please try again later
      </div>
    );

  return (
    <div>
      {resp.setup_completed ? (
        <div>Payment details setup complete</div>
      ) : (
        <>
          <p className="mb-6 w-1/2">
            To receive payments you will need to sign-up through our partner
            payment gateway and fill out details about your company etc.
          </p>
          <a
            href={resp.link}
            className="bg-white rounded-full shadow-lg px-6 py-3 w-fit text-sm hover:bg-red-500 hover:text-white"
          >
            Get started
          </a>
        </>
      )}
    </div>
  );
};

export default PaymentDetailsManagement;
