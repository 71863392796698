import SelectInput from "../../Inputs/Select";
import RegisterStepTitle from "../RegisterStepTitle";
import RegistrationFieldHolder from "../RegistrationFieldHolder";

const Industry: React.FC<any> = ({ register, control, errors, industries }) => {
  return (
    <div>
      <RegisterStepTitle
        title="Industry"
        subtitle="Please provide us with the following details about your business and industry. Fields marked with * are mandatory."
      />

      <RegistrationFieldHolder
        {...{
          control,
          errors,
          label: "Choose industries that apply.",
          id: "industries",
          required: true,
        }}
        field={({ field: { onChange, value } }: any): JSX.Element => (
          <SelectInput
            value={value}
            onChange={onChange}
            invalid={!!errors.postcode}
            placeholder="Choose Industries"
            options={industries.map((ind: any) => ({
              label: ind.name,
              value: ind.id,
            }))}
            selectProps={{}}
          />
        )}
      />
    </div>
  );
};

export default Industry;
