import React from "react";
import { ButtonProps, buttonRenderer } from "../Buttons.utils";

const WhiteButton: React.FC<ButtonProps> = (props) => {
  return buttonRenderer(
    props,
    "bg-white text-white hover:bg-gray-50 hover:disabled:bg-gray-100 text-gray-800"
  );
};

export default WhiteButton;
