import { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

const TableOrderDropdown: React.FC<any> = ({
  sortBy,
  setSortBy,
  order,
  setOrder,
  options,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const getOptions = () => {
    const keys = Object.keys(options);
    return keys.map((key) => ({
      value: key,
      label: options[key],
    }));
  };
  return (
    <div className="relative">
      <div
        className="bg-gray-200 rounded-md w-32 p-3 text-gray-600 text-center hover:bg-gray-300 cursor-pointer hover:text-black flex items-center justify-between text-sm"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        Sort By
        {showDropdown ? <HiChevronUp size={20} /> : <HiChevronDown size={20} />}
      </div>

      {showDropdown && (
        <div className="absolute right-0 top-14 bg-white rounded shadow w-56 z-20 overflow-hidden">
          {getOptions().map((option) => (
            <>
              <div
                key={option.value}
                className="w-full py-2 px-3 hover:bg-gray-500 hover:text-white cursor-pointer flex items-center justify-between text-sm"
                onClick={() => {
                  setSortBy(option.value);
                  setOrder("desc");
                  setShowDropdown(false);
                }}
              >
                {option.label} (desc)
                {sortBy === option.value && order === "desc" && (
                  <AiOutlineCheck size={20} className="text-green-600" />
                )}
              </div>

              <div
                key={option.value}
                className="w-full py-2 px-3 hover:bg-gray-500 hover:text-white cursor-pointer flex items-center justify-between text-sm"
                onClick={() => {
                  setSortBy(option.value);
                  setOrder("asc");
                  setShowDropdown(false);
                }}
              >
                {option.label} (asc)
                {sortBy === option.value && order === "asc" && (
                  <AiOutlineCheck size={20} className="text-green-600" />
                )}
              </div>
            </>
          ))}
        </div>
      )}
    </div>
  );
};

export default TableOrderDropdown;
