import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";

const ProfileDropdown: React.FC<any> = () => {
  const { user } = useAuth();

  const [shown, setShown] = useState(false);

  return (
    <div className="relative">
      <div
        onClick={() => setShown(!shown)}
        className="bg-green-500 rounded-full w-12 h-12 flex items-center justify-center text-white font-medium text-xl cursor-pointer hover:shadow-lg"
      >
        {user!.name.substring(0, 2).toUpperCase()}
      </div>
      {shown && (
        <div className="absolute bg-white top-0 left-14 rounded shadow w-48 z-30">
          <MenuItem path="/edit-profile" text="Edit Profile" />
          <MenuItem path="/switch-account" text="Switch Account" />
        </div>
      )}
    </div>
  );
};

const MenuItem: React.FC<any> = ({ path, text }) => {
  return (
    <Link
      to={path}
      className="block p-3 hover:bg-gray-100 cursor-pointer w-full"
    >
      {text}
    </Link>
  );
};
export default ProfileDropdown;
