import React, { useContext, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { RiPencilFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { AccountUser } from "../../../types";
import { ModalProps } from "../../../types/application";
import useConfirmDelete from "../../../utils/withConfirmDelete";
import DefaultButton from "../../Buttons/Default";
import RedButton from "../../Buttons/Red";
import axios from "../../../plugins/axios";
import SettingsContext from "../../../contexts/settings";

interface InviteOptionsModalProps extends ModalProps {
  invite: AccountUser;
}

const InviteOptionsModal: React.FC<InviteOptionsModalProps> = ({
  onCompleted,
  invite,
}) => {
  const { getSettings } = useContext(SettingsContext);

  const [loading, setLoading] = useState(false);
  const deleteUser = useConfirmDelete();

  const deleteUserById = async () => {
    setLoading(true);

    try {
      await axios.delete(`settings/invites/${invite.id}`);
    } catch (error) {
      toast("Failed to delete user", {
        position: "bottom-center",
        type: "error",
        theme: "dark",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    await getSettings();
    setLoading(false);
  };

  return (
    <div>
      <div
        className="flex justify-end h-6 w-full cursor-pointer"
        onClick={() => onCompleted()}
        role="button"
        tabIndex={0}
      >
        <AiOutlineClose className="w-6 text-gray-900" />
      </div>
      <div className="mx-auto flex items-center justify-center h-8 w-8">
        <RiPencilFill size={16} color="black" />
      </div>
      <div className="text-center mt-2">
        <h3
          className="text-lg leading-6 font-medium text-gray-900"
          id="modal-headline"
        >
          Manage Pending User
        </h3>
        <p className="leading-5 font-base text-sm text-gray-400">
          Manage {invite.name}&lsquo;s settings below
        </p>
      </div>
      <div className="flex mt-8">
        <DefaultButton
          title="Resend Invitation"
          type="submit"
          loading={loading}
          disabled={loading}
        />
      </div>
      <div className="flex mt-3">
        <RedButton
          title="Delete user"
          type="button"
          loading={loading}
          disabled={loading}
          onClick={() => deleteUser("DELETE USER", deleteUserById)}
        />
      </div>
    </div>
  );
};

export default InviteOptionsModal;
