import queryString from "query-string";

export const getParams = ({
  searchQuery,
  orderBy = "desc",
  sortBy,
  per_page = 10,
  current_page = 1,
  columns,
  other = [],
}: any) => {
  let result = "?";

  if (searchQuery) {
    result = `${result}&query=${searchQuery}`;
  }
  if (sortBy) {
    result = `${result}&sort=${sortBy}`;
  }
  if (columns) {
    result = `${result}&${queryString.stringify({ "columns[]": columns })}`;
  }

  if (other.length > 0) {
    result = `${result}&${other.join("&")}`;
  }

  result = `${result}&order=${orderBy}&page=${current_page}&perPage=${per_page}`;

  return result;
};
