import React, { useState } from "react";
import { ProductItemAction } from "../../types";

const AuditItem: React.FC<ProductItemAction> = ({ type, data, created_at }) => {
  const [open, setOpen] = useState(false);

  const getContent = (vari: any) => {
    if (vari.startsWith("file:")) {
      const variO = JSON.parse(vari.replace("file:", ""));
      return (
        <a
          href={variO.url}
          download
          className="text-red-600 font-medium text-sm"
        >
          Download
        </a>
      );
    }

    if (vari.startsWith("text:")) return vari.replace("text:", "");

    return JSON.stringify(vari);
  };
  return (
    <div className="max-w-xl rounded-lg shadow bg-white mb-4">
      <div
        className="flex items-center justify-between cursor-pointer hover:bg-gray-100 p-4"
        onClick={() => setOpen(!open)}
      >
        <h5>{type}</h5>
        <p>{new Date(created_at).toDateString()}</p>
      </div>
      {open && (
        <div className="p-4 border-t">
          {data &&
            Object.keys(data).map((key: any) => (
              <div>
                {/* @ts-ignore */}
                {key}: {getContent(data[key])}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default AuditItem;
