import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import SettingsContext from "../../../contexts/settings";
import { IndustryType } from "../../../types";
import Form from "../../Form";
import GhostCertificationField from "../../Inputs/GhostCertificationField";
import RegistrationFieldHolder from "../../NoAccount/RegistrationFieldHolder";

interface IndustryVerificationFormProps {
  industry: IndustryType;
}

const IndustryVerificationForm: React.FC<IndustryVerificationFormProps> = ({
  industry,
}) => {
  const navigate = useNavigate();

  const { getSettings } = useContext(SettingsContext);

  const getDefaults = () => ({});

  const onSubmit = async () => {
    await getSettings();
    navigate(`/settings/industries-and-certifications/${industry.slug}`);
  };

  return (
    <Form
      getDefaults={getDefaults}
      onCompleted={onSubmit}
      buttonTitle="Submit Form"
      endpoint="/manufacturers/industries"
      method="post"
      className="w-full"
      transform={(data) => ({ certifications: data, industry_id: industry.id })}
      formButtonHolderClassName="w-full md:w-1/2"
      render={({ errors, control }) => (
        <>
          <div className="my-6 border-b pb-8 mb-2">
            <h3 className="font-medium text-xl text-gray-500">
              {industry.name}
            </h3>
            <p className="text-sm mb-6 text-gray-600">
              Please fill out the following industry specific questions.
            </p>
            {industry.registration_rules.map((field: any) => (
              <div className="my-6">
                <RegistrationFieldHolder
                  {...{
                    control,
                    errors,
                    label: field.label,
                    id: field.id,
                    required: true,
                  }}
                  field={({ field: { onChange, value } }: any): JSX.Element => (
                    <GhostCertificationField
                      value={value}
                      onChange={onChange}
                      invalid={!!errors[field.id]}
                      placeholder={field.placeholder ?? ""}
                      field={field}
                    />
                  )}
                />
              </div>
            ))}
          </div>
        </>
      )}
    />
  );
};

export default IndustryVerificationForm;
