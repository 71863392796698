/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import ComingSoon from "../../components/ComingSoon";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import SettingsPageHeader from "../../components/SettingsPartsReUse/Header";
import { settingsRoutes } from "../../utils/settings.utls";
import axios from "../../plugins/axios";
import { IndustryType, ManufacturerSettingsType } from "../../types";
import SingleDocumentEditScreen from "../../components/SettingsTabs/PoliciesAndTerms/SingleEdit";
import IndustryViewManageScreen from "../../components/SettingsTabs/IndustriesAndCertifications/IndustryView";
import SettingsContext from "../../contexts/settings";

const SettingsPageTabItem: React.FC<{
  icon?: any;
  text: string;
  path?: string;
  active?: boolean;
}> = ({ icon = "", text, path = "", active = false }: any) => {
  return (
    <Link
      to={path}
      className={`block cursor-pointer my-2 text-md hover:text-green-600 ${
        active ? "text-green-600" : "text-gray-500"
      }`}
    >
      {icon} {text}
    </Link>
  );
};

const SettingsPage: React.FC<any> = () => {
  const location = useLocation();

  const [settings, setManufacturer] = useState<
    ManufacturerSettingsType | undefined
  >(undefined);

  const [availablePermissions, setAvailablePermissions] = useState<
    Record<string, unknown>
  >({});
  const [availableIndustries, setAvailableIndustries] = useState<
    IndustryType[]
  >([]);

  const [loaded, setLoaded] = useState(false);

  const getSettings = async () => {
    try {
      const {
        data: { data },
      } = await axios.get("/manufacturers/settings");
      setManufacturer(data as ManufacturerSettingsType);
    } catch (error) {}
    setLoaded(true);
  };

  const getPermissions = async () => {
    try {
      const { data } = await axios.get("/manufacturers/permissions");
      setAvailablePermissions(data);
    } catch (error) {}
    setLoaded(true);
  };

  const getIndustries = async () => {
    try {
      const {
        data: { data },
      } = await axios.get("/industries?with_rules=1");
      setAvailableIndustries(data);
    } catch (error) {}
    setLoaded(true);
  };

  useEffect(() => {
    getSettings();
    getPermissions();
    getIndustries();
  }, []);

  return (
    <DashboardLayout active="/settings">
      <div className="w-full overflow-hidden">
        <div className="w-full flex items-center justify-between h-20 p-4 border-b bg-gray-100">
          <h1 className="font-medium text-xl text-gray-700">Settings</h1>
          <div className="w-1/2" />
        </div>
        <div className="w-full h-full flex">
          <div className="w-1/4 bg-gray-100 h-full p-4 shadow">
            {settingsRoutes.map((group, index) => (
              <div key={index} className="mb-6">
                <h3 className="font-bold text-black text-md">{group.title}</h3>
                {group.children.map((child, childIndex) => (
                  <SettingsPageTabItem
                    key={childIndex}
                    text={child.title}
                    path={`/settings${child.path}`}
                    active={location.pathname === `/settings${child.path}`}
                  />
                ))}
              </div>
            ))}
          </div>
          <div className="w-full h-screen overflow-auto pb-32">
            {loaded && settings && (
              <SettingsContext.Provider
                value={{
                  settings,
                  getSettings,
                  availablePermissions,
                  availableIndustries,
                }}
              >
                <Routes>
                  {settingsRoutes
                    .map((sr) => sr.children)
                    .reduce(
                      (group = [], current = []) => [...current, ...group],
                      []
                    )
                    .map(
                      (
                        { path: routePath, subtitle, Component, title },
                        index
                      ) => (
                        <Route
                          path={routePath}
                          key={`${index}`}
                          element={
                            <>
                              <SettingsPageHeader
                                title={title}
                                subtitle={subtitle}
                              />
                              <div className="max-w-5xl mx-auto my-6">
                                {Component === null ? (
                                  <ComingSoon component={title} />
                                ) : (
                                  <Component />
                                )}
                              </div>
                            </>
                          }
                        />
                      )
                    )}
                  <Route
                    path="/settings/policies-and-terms/:documentId"
                    element={SingleDocumentEditScreen}
                  />
                  <Route
                    path="/settings/industries-and-certifications/:industrySlug"
                    element={IndustryViewManageScreen}
                  />
                </Routes>
              </SettingsContext.Provider>
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SettingsPage;
