import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";
import DefaultButton from "../../components/Buttons/Default";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import Modal from "../../components/Modal";
import AddProductModal from "../../components/Modals/AddProductModal";
import Table from "../../components/Table";

const ProductsIndex: React.FC<any> = () => {
  const [showCreate, setShowCreate] = useState(false);

  const columns = [
    {
      id: "product_name",
      Header: "Name",
      Cell: ({ row }: any) => (
        <Link className="link text-green-800" to={`/products/${row.id}`}>
          {row.product_name}
        </Link>
      ),
    },
    {
      id: "product_make",
      Header: "Make",
      accessor: "product_make",
    },
    {
      id: "product_model",
      Header: "Model",
      accessor: "product_model",
    },
    {
      id: "serial_number",
      Header: "Serial Number",
      accessor: "serial_number",
    },
    {
      id: "status",
      Header: "Status",
      accessor: "status",
      Cell: ({ row }: any) => {
        switch (row.status) {
          case "in_progress":
            return "In Progress";
          default:
            return "-";
        }
      },
    },
  ];

  return (
    <DashboardLayout active="/products">
      <div className="w-full">
        <div className="w-full flex items-center justify-between h-20 p-4 border-b bg-gray-100">
          <h1 className="font-medium text-xl text-gray-700">Products</h1>

          <div className="w-1/2" />
          <div>
            <DefaultButton
              type="button"
              title="New Product"
              before={<AiOutlinePlus className="mr-2" />}
              onClick={() => setShowCreate(true)}
            />
          </div>
        </div>

        <Table
          getAllRoute="manufacturers/items"
          defaultState={{
            columns,
            sortBy: "created_at",
          }}
        />
      </div>

      <Modal
        shown={showCreate}
        onClose={() => setShowCreate(false)}
        dismissible
      >
        <AddProductModal onClose={() => setShowCreate(false)} />
      </Modal>
    </DashboardLayout>
  );
};

export default ProductsIndex;
