import React from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import DashboardHeader from "../../components/Dashboard/Header";
import Table from "../../components/Table";

const ReviewsScreen: React.FC = () => {
  const columns = [
    {
      id: "title",
      Header: "Title",
      accessor: "title",
    },
    {
      id: "user",
      Header: "User Name",
      accessor: "user",
    },
    {
      id: "stars",
      Header: "Stars",
      accessor: "stars",
    },
    {
      id: "order_number",
      Header: "Order Number",
      Cell: ({ row }: any) => (
        <>
          {row.order ? (
            <Link className="link text-green-800" to={`/orders/${row.id}`}>
              {row.order?.order_number}
            </Link>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <DashboardLayout active="/dashboard/reviews">
      <div className="w-full">
        <DashboardHeader active="/dashboard/reviews" />
        <Table
          getAllRoute="manufacturers/reviews"
          defaultState={{
            columns,
            sortBy: "created_at",
          }}
        />
      </div>
    </DashboardLayout>
  );
};

export default ReviewsScreen;
