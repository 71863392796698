type RegisterStepTitleProps = {
  title: string;
  subtitle: string;
};
const RegisterStepTitle: React.FC<RegisterStepTitleProps> = ({
  title,
  subtitle,
}) => {
  return (
    <div className="my-6 max-w-lg">
      <h2 className="text-xl font-medium text-gray-600">{title}</h2>
      <h4 className="text-sm text-gray-500 mt-1">{subtitle}</h4>
    </div>
  );
};

export default RegisterStepTitle;
