import { useContext, useMemo } from "react";
import { GoVerified, GoUnverified, GoEye, GoPlus } from "react-icons/go";
import { Link } from "react-router-dom";
import SettingsContext from "../../../contexts/settings";

const VerifiedLabel = () => {
  return (
    <div className="flex bg-green-500 text-white rounded-full px-4 py-2 items-center justify-start">
      <span className="font-medium text-sm mr-2">Verified</span>
      <GoVerified />
    </div>
  );
};

const AwaitingApprovalLabel = () => {
  return (
    <div className="flex bg-red-400 text-white rounded-full px-4 py-2 items-center justify-start">
      <span className="font-medium text-sm mr-2">Approval Pending</span>
      <GoUnverified />
    </div>
  );
};

const NotSubmittedLabel = () => {
  return (
    <div className="flex bg-gray-50 text-gray-600 rounded-full px-4 py-2 items-center justify-start">
      <span className="font-medium text-sm mr-2">Not Submitted</span>
      <GoUnverified />
    </div>
  );
};

const SettingsTabIndustriesAndCertifications: React.FC = () => {
  const {
    settings: { industries },
    availableIndustries,
  } = useContext(SettingsContext);

  const manufacturerIndustries = useMemo(() => {
    return industries
      .filter((ind) =>
        availableIndustries.map((indIo) => indIo.id).includes(ind.id)
      )
      .map((indus) => ({ ...indus, submitted: true }));
  }, [industries, availableIndustries]);

  const unSubmittedIndustries = useMemo(() => {
    return availableIndustries
      .filter((ind) => !industries.map((indIo) => indIo.id).includes(ind.id))
      .map((indus) => ({ ...indus, submitted: false }));
  }, [industries, availableIndustries]);

  return (
    <div>
      {[...manufacturerIndustries, ...unSubmittedIndustries].map((industry) => (
        <div
          key={industry.id}
          className="bg-white rounded-lg p-8 flex items-center justify-between mb-3"
        >
          <h3 className="text-xl font-medium">{industry.name}</h3>

          <div className="flex items-center">
            {industry.submitted ? (
              <>
                {industry.verified ? (
                  <VerifiedLabel />
                ) : (
                  <AwaitingApprovalLabel />
                )}
              </>
            ) : (
              <NotSubmittedLabel />
            )}

            <Link
              to={`/settings/industries-and-certifications/${industry.slug}`}
              className="text-gray-600 cursor-pointer hover:text-black ml-6"
            >
              {industry.submitted ? <GoEye size={25} /> : <GoPlus size={25} />}
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SettingsTabIndustriesAndCertifications;
