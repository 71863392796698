import axios from "axios";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { IoMdCloseCircle } from "react-icons/io";

type FileInputProps = {
  value: any;
  onChange: any;
  invalid?: boolean;
  label?: string;
  id?: string;
  disabled?: boolean;
  theme?: string;
  placeholder?: string;
  className?: string;
  type?: "registration" | "product_action_attachment";
};

export default function FileInput({
  value = ["https://via.placeholder.com/150"],
  onChange,
  invalid = false,
  theme = "dark",
  type = "registration",
}: FileInputProps) {
  const [loading, setLoading] = useState(false); // eslint-disable-line

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => validateAndUploadFile(file));
  }, []); // eslint-disable-line

  const validateAndUploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const { data } = await axios.post(`files/${type}`, formData);
      onChange([data]);
    } catch (error) {}
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    maxFiles: 10,
  });

  const themeStyles =
    theme === "dark"
      ? "border-2 border-dashed p-4 text-sm border-gray-400 rounded"
      : "border-2 border-dashed flex items-center justify-middle p-4 text-sm border-gray-400 rounded";

  return (
    <div
      className={`${themeStyles} ${invalid && "border-red-600"}`}
      {...getRootProps()}
    >
      <div>
        {value &&
          value !== "" &&
          value.map((imag: any, index: number) => (
            <div
              key={index}
              className="relative h-20 w-20"
              onClick={(e) => {
                onChange(
                  value.filter((img: any, ind: number) => ind !== index)
                );
                e.preventDefault();
              }}
            >
              <IoMdCloseCircle
                className="absolute -right-2 -top-2 text-red-600 hover:text-red-900 cursor-pointer shadow rounded-full"
                size={22}
              />
              <img
                src={imag.url}
                alt={`uploaded file at ${index}`}
                className="h-full w-full rounded mr-4"
              />
            </div>
          ))}
      </div>
      <div
        className={`w-full text-center font-medium text-sm text-gray-500 hover:text-black cursor-pointer ${
          value?.length && "border-t border-gray-200 mt-2 pt-2"
        }`}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drop files here, or click to select files</p>
        )}
      </div>
    </div>
  );
  //   return (
  //     <input
  //       id={id}
  //       name={id}
  //       type="text"
  //       value={value ?? ""}
  //       onChange={onChange}
  //       disabled={disabled}
  //       className={`form-input px-3 py-3 block w-full sm:text-sm sm:leading-5 border-none focus:border-black rounded shadow-sm ring-1 transition duration-500 ease-in-out focus:outline-none disabled:opacity-75 ${themeStyles} ${
  //         invalid ? "ring-red-600" : "ring-transparent"
  //       } ${className}`}
  //       placeholder={placeholder}
  //     />
  //   );
}
