/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import { ComponentType, FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../plugins/axios";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import { ProductItem } from "../types";

export function handlesSingularModel<T>(
  Component: ComponentType<T>,
  apiPath: string,
  urlParam = "id"
): FunctionComponent<T> {
  const PageWithModelScope: FunctionComponent<T> = (props: any) => {
    const params = useParams();

    const [item, setItem] = useState<ProductItem | null>(null);
    const [loading, setLoading] = useState(true);
    const [errored, setErrored] = useState(false);

    const getItem = async () => {
      setLoading(true);
      try {
        const {
          data: { data },
        } = await axios.get(`${apiPath}/${params[urlParam]}`);
        setItem(data);
      } catch (error) {
        setErrored(true);
      }
      setLoading(false);
    };

    useEffect(() => {
      getItem();
    }, [params[urlParam]]); // eslint-disable-line

    return (
      <DashboardLayout>
        {loading && <div>Loading...</div>}
        {errored && <div>Sorry there was an error</div>}
        {!loading && !errored && (
          <Component {...props} item={item} refresh={getItem} />
        )}
      </DashboardLayout>
    );
  };

  return PageWithModelScope;
}

export default {};
