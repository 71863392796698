const Table: React.FC<any> = ({ columns, data }) => {
  return (
    <table className="min-w-full divide-y divide-gray-200 text-left">
      <thead className="bg-gray-50">
        <tr>
          {columns.map((column: any, index: number) => (
            <th
              key={index}
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {column.Header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 text-gray-600">
        {data.map((row: any, index: number) => {
          return (
            <tr key={index}>
              {columns.map((column: any, ind2: number) => (
                <th
                  className="px-6 py-4 whitespace-nowrap font-normal"
                  key={ind2}
                >
                  {column.Cell ? <column.Cell row={row} /> : row[column.id]}
                </th>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
