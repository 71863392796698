import { useContext, useMemo } from "react";
import { Controller } from "react-hook-form";
import TextInput from "../../Inputs/TextInput";
import Form from "../../Form";
import FormFieldErrors from "../../Form/FormFieldErrors";
import SelectInput from "../../Inputs/Select";
import SettingsContext from "../../../contexts/settings";

const CreateRoleForm: React.FC<any> = ({ onCompleted }) => {
  const { getSettings, availablePermissions } = useContext(SettingsContext);

  const permissionsList = useMemo(() => {
    return Object.keys(availablePermissions).map((permKey) => ({
      label: permKey,
      value: permKey,
    }));
  }, [availablePermissions]);
  const getDefaults = () => {
    return {
      name: "",
      email: "",
      role: "user",
    };
  };

  const onSubmit = async () => {
    await getSettings();
    onCompleted();
  };

  return (
    <Form
      getDefaults={getDefaults}
      onCompleted={onSubmit}
      buttonTitle="Save Role"
      endpoint="manufacturers/roles"
      method="post"
      className="w-full md:max-w-2xl"
      formButtonHolderClassName="w-full md:w-1/2"
      render={({ errors, control }) => (
        <>
          <div className="mb-5">
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Create Role
            </h3>
            <p className="text-gray-600 text-sm">
              Fill out the details below to create a new account role
            </p>
          </div>
          <div className="mb-4 mt-6">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.name}
                  placeholder="Enter role name"
                />
              )}
            />

            <FormFieldErrors
              errors={errors}
              field="name"
              requiredMessage="Role name is required"
            />
          </div>

          <div className="mb-4 mt-6">
            <Controller
              name="permissions"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <SelectInput
                  value={permissionsList.filter((perm) =>
                    value.includes(perm.value)
                  )}
                  placeholder="Choose permissions"
                  onChange={(v: any) => {
                    onChange(v.map((pk: { value: string }) => pk.value));
                  }}
                  selectProps={{}}
                  options={permissionsList}
                />
              )}
            />

            <FormFieldErrors
              errors={errors}
              field="permissions"
              requiredMessage="Please select at least one permission for your role"
            />
          </div>
        </>
      )}
    />
  );
};

export default CreateRoleForm;
