import React, { MouseEventHandler } from "react";

export type ButtonProps = {
  title: string | JSX.Element;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  type?: "button" | "submit" | "reset" | undefined;
  before?: JSX.Element | null;
  after?: JSX.Element | null;
  disabled?: boolean;
  loading?: boolean;
};

export const buttonRenderer = (
  {
    title,
    onClick,
    type = "submit",
    before,
    after,
    disabled = false,
    loading = false,
  }: ButtonProps,
  className: string
): JSX.Element => {
  const styleWithoutColours =
    "rounded-md shadow py-2 px-4 flex items-center justify-center w-full font-sm disabled:opacity-50 disabled:cursor-not-allowed ";
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`${styleWithoutColours} ${className}`}
    >
      {loading ? (
        <span>Loading ...</span>
      ) : (
        <>
          {before}
          {title}
          {after}
        </>
      )}
    </button>
  );
};
