/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useState } from "react";
import { IoIosSettings } from "react-icons/io";
import SettingsContext from "../../contexts/settings";
import { AccountUser } from "../../types";
import { useAuth } from "../../utils/useAuth";
import DefaultButton from "../Buttons/Default";
import InviteUserToAccountForm from "../Forms/InviteUserToAccountForm";
import Modal from "../Modal";
import InviteOptionsModal from "../Modals/Users/InviteOptionsForm";
import UpdateUserModal from "../Modals/Users/UpdateUserModal";

const SettingsTabUsers: React.FC = () => {
  const auth = useAuth();
  const { settings } = useContext(SettingsContext);
  const [showAddUser, setShowAddUser] = useState(false);
  const [editUser, setEditUser] = useState<AccountUser | null>(null);
  const [editInvite, setEditInvite] = useState<AccountUser | null>(null);

  return (
    <div>
      <table
        className="table-auto border-separate mr-0 w-full"
        style={{ borderSpacing: "0 1.2rem" }}
      >
        <thead className="opacity-100 bg-opacity-100">
          <tr>
            <td className="text-left text-md leading-4 font-large text-gray-800 tracking-wider opacity-100 bg-opacity-100 pl-6">
              Name
            </td>
            <td className="text-left text-md leading-4 font-large text-gray-800 tracking-wider">
              Email
            </td>
            <td className="text-left text-md leading-4 font-large text-gray-800 tracking-wider">
              Role
            </td>
            <td className="px-6 lg:w-10 w-12" width="5%" />
          </tr>
        </thead>
        <tbody className="max-h-124 w-full">
          {settings.users.map((user: any, index: number) => (
            <tr
              className="bg-gray-200 bg-opacity-50 mb-6 h-12 pl-6 overflow-hidden w-full"
              key={index}
            >
              <td className="whitespace-no-wrap text-sm leading-5 font-regular text-black opacity-75 pl-6">
                {user.name}
              </td>
              <td className="whitespace-no-wrap text-sm font-light leading-5 text-black opacity-50 ">
                {user.email}
              </td>
              <td className="whitespace-no-wrap text-sm font-light leading-5 text-black opacity-50 ">
                {user.role}
              </td>
              <td width="5%" className="">
                {user.email === auth?.user?.email || user.role === "owner" ? (
                  <div className="block w-5 h-5 mr-2 lg:mr-0" />
                ) : (
                  <button
                    type="button"
                    className="block cursor-pointer text-gray-800 hover:text-black"
                    onClick={() => setEditUser(user)}
                  >
                    <IoIosSettings size={30} />
                  </button>
                )}
              </td>
            </tr>
          ))}
          {settings.invites.map((user: any, index: number) => (
            <tr
              className="bg-gray-200 bg-opacity-50 mb-6 h-12 pl-6 overflow-hidden w-full"
              key={index}
            >
              <td className="whitespace-no-wrap text-sm leading-5 font-regular text-black opacity-75 pl-6">
                {user.name} <strong>(Pending)</strong>
              </td>
              <td className="whitespace-no-wrap text-sm font-light leading-5 text-black opacity-50 ">
                {user.email}
              </td>
              <td className="whitespace-no-wrap text-sm font-light leading-5 text-black opacity-50 ">
                {user.role}
              </td>
              <td width="5%" className="">
                <button
                  type="button"
                  className="block cursor-pointer text-gray-800 hover:text-black"
                  onClick={() => setEditInvite(user)}
                >
                  <IoIosSettings size={30} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="w-full flex justify-between items-center mt-4">
        <div />
        <div>
          <DefaultButton
            title="Add User"
            onClick={() => setShowAddUser(true)}
          />
        </div>
      </div>
      <Modal
        shown={showAddUser}
        onClose={() => setShowAddUser(false)}
        dismissible
      >
        <InviteUserToAccountForm
          onCompleted={() => {
            setShowAddUser(false);
          }}
        />
      </Modal>
      <Modal
        shown={editUser !== null}
        onClose={() => setEditUser(null)}
        dismissible
      >
        <UpdateUserModal
          user={editUser!}
          onCompleted={() => {
            setEditUser(null);
          }}
        />
      </Modal>
      <Modal
        shown={editInvite !== null}
        onClose={() => setEditInvite(null)}
        dismissible
      >
        <InviteOptionsModal
          invite={editInvite!}
          onCompleted={() => {
            setEditInvite(null);
          }}
        />
      </Modal>
    </div>
  );
};

export default SettingsTabUsers;
