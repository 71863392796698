import { Controller } from "react-hook-form";
import { errorStyle, labelStyle } from "../../../constants/styles";

export default function FieldHolder({
  control,
  errors,
  label,
  id,
  field,
  required = false,
}: any) {
  const errorsObj = _get(errors, id, null);
  return (
    <div className="mb-4">
      <label className={labelStyle}>
        {label}
        {required && <span className="ml-1 text-gray-400 font-sm">*</span>}
      </label>

      <div className="mt-4 rounded-md shadow-sm">
        <Controller
          name={id}
          control={control}
          defaultValue=""
          rules={{
            required,
          }}
          render={field}
        />
      </div>
      {errorsObj && errorsObj.type === "required" && (
        <span className={errorStyle}>{label} is required</span>
      )}
      {errorsObj && errorsObj.type === "api" && (
        <span className={errorStyle}>{errorsObj.message}</span>
      )}
    </div>
  );
}

// eslint-disable-next-line no-underscore-dangle
export function _get(object: Object, path: any, defval = null) {
  // eslint-disable-next-line no-param-reassign
  if (typeof path === "string") path = path.split(".");
  return path.reduce(
    (xs: any, x: any) => (xs && xs[x] ? xs[x] : defval),
    object
  );
}
