import { UseFormSetError } from "react-hook-form";

/**
 *
 * Takes the default laravel 422 error response and parses it for react from hooks
 *
 * @param {*} response
 * @param {*} setError
 */
export const errorParser = (
  {
    response: {
      data: { errors },
    },
  }: any,
  setError: any = () => {}
) => {
  const keys = Object.keys(errors);

  keys.forEach((key) => {
    setError(key, { type: "api", message: errors[key][0] });
  });
};
