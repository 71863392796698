import { useMemo } from "react";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";

interface DashboardCountsProps {
  data: any;
  currentView: "this_week" | "this_month" | "this_year";
}

interface CountBlockProps {
  title: string;
  previous: number;
  current: number;
  prefix?: string;
}

const CountBlock: React.FC<CountBlockProps> = ({
  title,
  previous,
  current,
  prefix = "",
}) => {
  const isUp = current >= previous;

  const percentage = Math.round(
    Math.abs(((previous - current) / previous) * 100)
  );

  const getClassName = () => {
    if (current === previous) {
      return "bg-gradient-to-r p-4 rounded-lg shadow-lg flex justify-between items-center text-gray-800 bg-white";
    }

    return ` ${
      isUp ? "bg-green-200 from-green-500" : "bg-red-200 from-red-500"
    } bg-gradient-to-r  p-4 rounded-lg shadow-lg flex justify-between items-center text-white`;
  };

  const getInnerClassName = () => {
    if (current === previous) {
      return "flex flex-col items-center font-bold text-gray-800";
    }

    return `flex flex-col items-center font-bold  ${
      isUp ? "text-green-800" : "text-red-600"
    }`;
  };

  return (
    <div className={getClassName()}>
      <div>
        <h3>{title}</h3>
        <h4 className="text-5xl font-light">
          {prefix}
          {current}
        </h4>
      </div>
      <div className={getInnerClassName()}>
        {current !== previous && (
          <>
            {isUp ? (
              <AiOutlineArrowUp size={20} />
            ) : (
              <AiOutlineArrowDown size={20} />
            )}
            {Number.isFinite(percentage) ? percentage : "100"}%
          </>
        )}
      </div>
    </div>
  );
};

const DashboardCounts: React.FC<DashboardCountsProps> = ({
  data,
  currentView,
}) => {
  const currentObject = useMemo(() => {
    switch (currentView) {
      case "this_week":
        return data.weekly;
      case "this_month":
        return data.monthly;
      default:
        return data.yearly;
    }
  }, [currentView, data]);
  return (
    <div className="grid grid-cols-4 gap-3 my-6">
      <CountBlock
        title="New Customers"
        previous={currentObject.new_customers.previous}
        current={currentObject.new_customers.current}
      />
      <CountBlock
        title="New Orders"
        previous={currentObject.new_orders.previous}
        current={currentObject.new_orders.current}
      />
      <CountBlock
        title="Average Sale"
        previous={currentObject.average_sale.previous}
        current={currentObject.average_sale.current}
        prefix="£"
      />
      <CountBlock
        title="Gross Profit"
        previous={currentObject.gross_profit.previous}
        current={currentObject.gross_profit.current}
        prefix="£"
      />
    </div>
  );
};

export default DashboardCounts;
