import React from "react";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ProductItem } from "../../../types";
import Form from "../../Form";
import FormFieldErrors from "../../Form/FormFieldErrors";
import RepeaterField from "../../Inputs/RepeaterField";
import TextInput from "../../Inputs/TextInput";

interface AddProductItemActionFormProps {
  item: ProductItem;
}

const AddProductItemActionForm: React.FC<AddProductItemActionFormProps> = ({
  item,
}) => {
  const navigate = useNavigate();
  const getDefaults = () => {
    return {
      type: "",
      data: {},
    };
  };

  const onSubmit = async () => {
    navigate(`/products/${item.id}`);
  };

  return (
    <Form
      getDefaults={getDefaults}
      onCompleted={onSubmit}
      buttonTitle="Save Action"
      endpoint={`/manufacturers/items/${item.id}/actions`}
      method="post"
      className="w-full md:max-w-2xl"
      formButtonHolderClassName="w-full md:w-1/2"
      render={({ errors, control }: any) => (
        <>
          <div className="mb-4 mt-6">
            <Controller
              name="type"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.name}
                  placeholder="Action Type"
                />
              )}
            />

            <FormFieldErrors
              errors={errors}
              field="name"
              requiredMessage="Action Type is required"
            />
          </div>

          <div className="mb-10 mt-6">
            <Controller
              name="data"
              control={control}
              defaultValue=""
              rules={{
                required: false,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <RepeaterField
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.name}
                  placeholder="Action Details"
                />
              )}
            />

            <FormFieldErrors
              errors={errors}
              field="data"
              requiredMessage="Field is required"
            />
          </div>
        </>
      )}
    />
  );
};

export default AddProductItemActionForm;
