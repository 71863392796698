import { Controller } from "react-hook-form";
import { useAuth } from "../../../utils/useAuth";
import Form from "../../Form";
import FormFieldErrors from "../../Form/FormFieldErrors";
import TextInput from "../../Inputs/TextInput";

const UpdatePasswordForm: React.FC<any> = () => {
  const { fetchUser } = useAuth();

  const getDefaults = () => {
    return {
      password: "",
      new_password: "",
      new_password_confirmation: "",
    };
  };

  const onSubmit = async (responseData: any) => {
    await fetchUser();
  };

  return (
    <Form
      getDefaults={getDefaults}
      onCompleted={onSubmit}
      buttonTitle="Change Password"
      endpoint="user"
      method="put"
      className="w-full md:max-w-2xl"
      formButtonHolderClassName="w-full md:w-1/2"
      render={({ errors, control }) => (
        <>
          <div className="my-4">
            <Controller
              name="password"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.password}
                  placeholder="Current Password"
                  type="password"
                />
              )}
            />
            <FormFieldErrors
              errors={errors}
              field="password"
              requiredMessage="Password is required"
            />
          </div>
          <div className="my-4">
            <Controller
              name="new_password"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.new_password}
                  placeholder="Password"
                  type="password"
                />
              )}
            />
            <FormFieldErrors
              errors={errors}
              field="new_password"
              requiredMessage="Password is required"
            />
          </div>
          <div className="my-4">
            <Controller
              name="new_password_confirmation"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.new_password_confirmation}
                  placeholder="Confirm Password"
                  type="password"
                />
              )}
            />

            <FormFieldErrors
              errors={errors}
              field="new_password_confirmation"
              requiredMessage="Please confirm your password"
            />
          </div>
        </>
      )}
    />
  );
};

export default UpdatePasswordForm;
