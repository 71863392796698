import { useState } from "react";
import axios from "../../../../plugins/axios";
import AsyncSelectInput, { AsyncSelectInputProps } from "../AsyncSelect";

interface AsyncModelSelectProps extends AsyncSelectInputProps {
  endpoint: string;
  transform: (item: any) => object;
}

const AsyncModelSelect: React.FC<AsyncModelSelectProps> = (props) => {
  const [searchTerm, setSearchTerm] = useState("");

  const doSearch = async (term: string, callback: (options: []) => void) => {
    if (term.length < 2) return;

    try {
      const {
        data: { data },
      } = await axios.get("/products", {
        params: {
          query: searchTerm,
        },
      });
      callback(data.map(props.transform));
    } catch (error) {
      callback([]);
    }
  };

  return (
    <AsyncSelectInput
      {...props}
      selectProps={{
        cacheOptions: true,
        defaultOptions: [],
        clearable: true,
        loadOptions: doSearch,
        onInputChange: (val: string) => setSearchTerm(val),
      }}
    />
  );
};

export default AsyncModelSelect;
