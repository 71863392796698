import { IoIosCheckmarkCircle } from "react-icons/io";
import { _t } from "../../utils/language";
import { useAuth } from "../../utils/useAuth";
import { registrationSteps, RegisterStep, stepIsValid } from "./register.utils";

const NoAccountSidebar = ({
  type,
  registerStep,
  registrationState,
  setRegisterStep,
}: any) => {
  const { logout } = useAuth();

  const getTitle = () => {
    switch (type) {
      case "pick":
        return "Choose Organization";
      case "create":
        return "Register Organization";
      case "join":
        return "Join Organization";
      case "register-success":
        return "Registration Successful";
      default:
        return "No Organization";
    }
  };

  const linkStyles = {
    default:
      "block text-gray-600 flex items-center justify-start mb-2 cursor-pointer",
    active:
      "block text-green-600 flex items-center justify-start mb-2 cursor-pointer",
    complete:
      "block text-green-600 flex items-center justify-start mb-2 cursor-pointer line-through",
    invalid:
      "block text-red-900 flex items-center justify-start mb-2 cursor-pointer",
  };

  const iconStyles = {
    default: "text-gray-600 opacity-25 text-2xl mr-2",
    active: "text-gray-600 opacity-25 text-2xl mr-2",
    complete: "text-green-600 text-2xl mr-2",
    invalid: "text-red-900 text-2xl mr-2",
  };

  const getStepClass = (step: RegisterStep): string => {
    const valid = stepIsValid(step.id, registrationState);
    const active = registerStep === step.id;
    if (valid === "invalid" && !active) return linkStyles.invalid;
    if (valid === "invalid" && active) return linkStyles.active;
    if (valid === "valid") return linkStyles.complete;
    if (active) return linkStyles.active;

    return linkStyles.default;
  };

  const getIconClass = (step: RegisterStep): string => {
    const valid = stepIsValid(step.id, registrationState);
    const active = registerStep === step.id;
    if (valid === "invalid" && !active) return iconStyles.invalid;
    if (valid === "invalid" && active) return iconStyles.invalid;
    if (valid === "valid") return iconStyles.complete;
    if (active) return iconStyles.active;

    return iconStyles.default;
  };
  return (
    <div className="w-1/4 bg-gray-200 py-8 px-10 flex items-start justify-between flex-col rounded-r-2xl shadow flex-shrink-0">
      <img
        src="/images/logo.svg"
        className="w-32"
        alt={`${_t("default.name")} Logo`}
      />
      <div>
        <h1 className="text-gray-700 text-xl">{getTitle()}</h1>
        {type === "create" && (
          <div className="block mt-8">
            {registrationSteps.map((step: RegisterStep, index: number) => {
              return (
                <div
                  className={getStepClass(step)}
                  key={step.id}
                  onClick={() => setRegisterStep(step.id)}
                >
                  <IoIosCheckmarkCircle className={getIconClass(step)} />{" "}
                  {step.title}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div>
        <div className="block text-gray-700 text-sm font-light ">
          Copyright {new Date().getFullYear()}, {_t("general.name")} &nbsp; |
          &nbsp;{" "}
          <div
            className="font-medium cursor-pointer hover:text-gray-900 inline-block"
            onClick={() => {
              logout();
            }}
          >
            Logout
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoAccountSidebar;
