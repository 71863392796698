/* eslint-disable react/jsx-props-no-spreading */
import axios from "axios";
import { useEffect, useState } from "react";

import { HiOutlineSearch } from "react-icons/hi";
import TablePagination from "./Pagination";
import { getParams } from "./Utils";
import StaticTable from "./Table";
import TableOrderDropdown from "./FiltersAndSorting/Order";
import TableFilterDropdown from "./FiltersAndSorting/Filter";

const Table: React.FC<any> = ({
  getAllRoute,
  defaultState,
  loadingText = "Loading Items...",
  searchPlaceholder = "Start typing to search items",
  sortOptions = { id: "id" },
  filterComponent = () => {},
  filterTransformer = () => {},
}) => {
  const ssState = {
    columns: [],
    searchQuery: "",
    total_pages: 1,
    current_page: 1,
    per_page: 10,
    total: 0,
    from: 0,
    order: "desc",
    sortBy: "id",
    ...defaultState,
  };

  const [columns, setColumns] = useState(ssState.columns); // eslint-disable-line
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState<any>(undefined); // eslint-disable-line
  const [apiError, setApiError] = useState<any>(undefined); // eslint-disable-line
  const [searchQuery, setSearchQuery] = useState(ssState.searchQuery); // eslint-disable-line
  const [filters, setFilters] = useState({});

  const [paging, setPaging] = useState({
    from: ssState.from,
    total: ssState.total,
    total_pages: ssState.total_pages,
    current_page: ssState.current_page,
    per_page: ssState.perPage,
  });

  const [sortBy, setSortBy] = useState(ssState.sortBy); // eslint-disable-line
  const [order, setOrder] = useState(ssState.order); // eslint-disable-line

  const fetchTableData = async () => {
    const { current_page, per_page } = paging;

    const listFilters = filterTransformer(filters);

    const params = getParams({
      searchQuery,
      order,
      sortBy,
      per_page,
      current_page,
      other: listFilters,
    });

    try {
      const {
        data: { data, meta },
      } = await axios.get(`${getAllRoute}${params}`);

      const {
        current_page: currentPage,
        per_page: perPage,
        last_page,
        total,
        from,
      } = meta.pagination ? meta.pagination : meta;

      setPaging({
        from,
        total,
        total_pages: last_page,
        current_page: currentPage,
        per_page: perPage,
      });

      setTableData(data);
    } catch (error) {
      setApiError(error);
    } finally {
      setLoading(false);
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (!tableData) {
      setLoading(true);
      fetchTableData();
    }
  }, []); // eslint-disable-line

  /* eslint-disable  */
  useEffect(() => {
    setUpdating(true);
    fetchTableData();
  }, [
    searchQuery,
    paging.current_page,
    paging.per_page,
    sortBy,
    order,
    filters,
  ]); // eslint-disable-line
  /* eslint-enable  */

  const tableProps = {
    data: tableData,
    // manualSort: { sort: sortBy, order: order },
    // setManualSort: handleSort,
  };

  return (
    <>
      {loading ? (
        <div className="w-full p-10 text-gray-500 bg-gray-200 text-center">
          {loadingText}
        </div>
      ) : (
        <div className=" w-full overflow-hidden">
          <div className="p-4">
            <form
              className="flex items-center justify-between"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="flex items-center w-full">
                <HiOutlineSearch size={22} />
                <input
                  className="form-field appearance-none w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-none focus:border-none text-sm border-none ml-1 bg-transparent outline-none"
                  id="search"
                  type="text"
                  placeholder={searchPlaceholder}
                  autoComplete="search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="flex items-center justify-center">
                <TableFilterDropdown
                  filterComponent={filterComponent}
                  {...{ filters, setFilters }}
                />
                <TableOrderDropdown
                  {...{
                    sortBy,
                    setSortBy,
                    order,
                    setOrder,
                    options: sortOptions,
                  }}
                />
              </div>
            </form>
          </div>
          <div
            className="relative"
            style={{ minHeight: "calc(100vh - 10rem)" }}
          >
            <StaticTable columns={columns} {...tableProps} />
            {updating && (
              <div className="absolute w-full h-screen top-0 left-0 p-10 text-gray-900 bg-gray-200 text-center bg-opacity-50 z-10">
                Updating Results...
              </div>
            )}
            {tableData.length === 0 && (
              <div className="w-full text-center bg-gray-200 p-4 text-gray-600">
                {" "}
                No items found matching search
              </div>
            )}
            {tableData.length > 0 && (
              <TablePagination
                {...paging}
                setPaging={(index: number) => {
                  setPaging({ ...paging, current_page: index });
                }}
                pageSizes={ssState.pageSizes}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
