import React from "react";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import DashboardHeader from "../../components/Dashboard/Header";
import { useAuth } from "../../utils/useAuth";
import DashboardSetupComponent from "../../components/Dashboard/Setup";
import ManufacturerDashboard from "../../components/Dashboard/Dashboard";

const Dashboard: React.FC = () => {
  const { account } = useAuth();
  return (
    <DashboardLayout active="/dashboard">
      <div className="w-full h-full overflow-auto">
        <DashboardHeader active="/dashboard" />

        <div className="max-w-7xl mx-auto my-10 p-4">
          {account.setup_completed ? (
            <ManufacturerDashboard />
          ) : (
            <DashboardSetupComponent />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
