import DashboardLayout from "../../components/Layouts/DashboardLayout";
import EditProfileForm from "../../components/Forms/EditProfileForm";
import UpdatePasswordForm from "../../components/Forms/UpdatePasswordForm";

const EditProfile: React.FC<any> = () => {
  return (
    <DashboardLayout active="index">
      <div className="grid grid-cols-1 md:grid-cols-2 w-full">
        <div className="bg-gray-50 rounded-lg p-10 mr-4 w-full md:max-w-2xl">
          <h1 className="text-xl font-medium">Edit Personal Details</h1>
          <EditProfileForm />
        </div>
        <div className="bg-gray-50 rounded-lg p-10 w-full md:max-w-2xl">
          <h1 className="text-xl font-medium">Change Password</h1>
          <UpdatePasswordForm />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default EditProfile;
