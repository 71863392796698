import { Link } from "react-router-dom";
import queryString from "query-string";
import AsyncModelSelect from "../../components/Inputs/Select/AsyncModelSelect";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import Table from "../../components/Table";

const BuyEolItemsPage: React.FC<any> = () => {
  const columns = [
    {
      id: "product_name",
      Header: "Name",
      accessor: "product.name",
      Cell: ({ row }: any) => row.product.name,
    },
    {
      id: "product_make",
      Header: "Make",
      accessor: "product.make",
      Cell: ({ row }: any) => row.product.make,
    },
    {
      id: "product_model",
      Header: "Model",
      accessor: "product.model",
      Cell: ({ row }: any) => row.product.model,
    },
    {
      id: "serial_number",
      Header: "Serial Number",
      accessor: "serial_number",
    },
    {
      id: "offers",
      Header: "Offers",
      accessor: "offers",
      Cell: ({ row }: any) => row.offers.length,
    },
    {
      id: "actions",
      Cell: ({ row }: any) => (
        <Link
          to={`/buy-eol-items/${row.id}`}
          className="text-green-500 hover:text-green-800 cursor-pointer text-sm font-bold"
        >
          VIEW
        </Link>
      ),
    },
  ];

  return (
    <DashboardLayout active="/buy-eol-items">
      <div className="w-full">
        <div className="w-full flex items-center justify-between h-20 p-4 border-b bg-gray-100">
          <h1 className="font-medium text-xl text-gray-700">
            Buy end of life items
          </h1>

          <div className="w-1/2" />
          <div />
        </div>

        <Table
          getAllRoute="manufacturers/eol-items"
          defaultState={{
            columns,
            sortBy: "eol_items.created_at",
          }}
          filterTransformer={(filters: any) => {
            return [
              queryString.stringify({
                "product_ids[]":
                  filters?.products?.map((prod: any) => prod.value) ?? [],
              }),
            ];
          }}
          filterComponent={({ filters, setFilters }: any) => (
            <div
              className="absolute right-0 top-14 bg-white rounded shadow w-full z-20 overflow-hidden p-6 h-96"
              style={{ width: "600px" }}
            >
              <div className="flex items-center justify-between">
                <div>Products</div>
                <div className="w-2/3">
                  <AsyncModelSelect
                    value={filters.products ?? []}
                    onChange={(v: any) =>
                      setFilters({ ...filters, products: v })
                    }
                    placeholder="Search products"
                    selectProps={{}}
                    className="z-30"
                    isMulti
                    endpoint="/products"
                    transform={(pr: any) => ({ value: pr.id, label: pr.name })}
                  />
                </div>
              </div>
              {/* <div className="flex items-center justify-between mt-5">
                <div>Categories</div>
                <div className="w-2/3">
                  <AsyncSelectInput
                    value={null}
                    onChange={(v: any) => {}}
                    placeholder="Search categories"
                    selectProps={{}}
                    className="z-30"
                    isMulti={true}
                  />
                </div>
              </div> */}
            </div>
          )}
          sortOptions={{
            "products.name": "Name",
            "products.make": "Make",
            "products.model": "Model",
            "eol_items.created_at": "Created",
          }}
        />
      </div>
    </DashboardLayout>
  );
};

export default BuyEolItemsPage;
