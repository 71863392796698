import { RiRecycleFill, RiHome3Line, RiSettings3Line } from "react-icons/ri";
import { BsGridFill } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { Link } from "react-router-dom";
import ProfileDropdown from "../../ProfileDropdown";

interface MainSidebarProps {
  active?: string;
}

const MainSidebar: React.FC<MainSidebarProps> = ({ active }) => {
  return (
    <div className="h-full border-r border-gray-200 w-24 flex flex-col justify-between items-center py-8 flex-grow-0 flex-shrink-0">
      <ProfileDropdown />
      <div>
        <SidebarLink
          active={active}
          path="/dashboard"
          icon={<RiHome3Line size={26} />}
        />
        <SidebarLink
          active={active}
          path="/buy-eol-items"
          icon={<RiRecycleFill size={26} />}
        />
        <SidebarLink
          active={active}
          path="/products"
          icon={<BsGridFill size={25} />}
        />
        <SidebarLink
          active={active}
          path="/settings"
          icon={<RiSettings3Line size={26} />}
        />
      </div>
      <Link
        to="/logout"
        className="rounded-full hover:bg-gray-200 cursor-pointer p-2"
      >
        <FiLogOut size={26} />
      </Link>
    </div>
  );
};

interface SidebarLinkProps {
  active?: string;
  path: string;
  icon?: object;
}

const SidebarLink: React.FC<SidebarLinkProps> = ({ active, path, icon }) => {
  const classes =
    "block mb-3 rounded-full text-gray-600 hover:text-black hover:bg-gray-200 cursor-pointer p-2";
  return (
    <Link
      to={path}
      className={`${
        active === path ? "text-black" : "text-gray-500"
      } ${classes}`}
    >
      {icon}
    </Link>
  );
};

export default MainSidebar;
