type LangOption = "uk" | "in";

const getSiteShortname = (): LangOption => {
  try {
    return process?.env?.REACT_APP_LOCATION as LangOption;
  } catch (error) {
    return "uk";
  }
};

export default getSiteShortname;
