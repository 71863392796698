import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logout from "./pages/auth/logout";
import SwitchAccount from "./pages/auth/switch-account";
import BuyEolItemsPage from "./pages/buy-eol-items";
import ViewEOLItemPage from "./pages/buy-eol-items/[id]";
import Dashboard from "./pages/dashboard";
import OrdersScreen from "./pages/dashboard/orders";
import ReviewsScreen from "./pages/dashboard/reviews";
import EditProfile from "./pages/edit-profile";
import ProductsIndex from "./pages/products";
import ProductShow from "./pages/products/[id]";
import AddActionScreen from "./pages/products/[id]/add-action";
import SettingsPage from "./pages/settings";
import requiresAuth from "./utils/requiresAuth";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/orders" element={<OrdersScreen />} />
        <Route path="/dashboard/reviews" element={<ReviewsScreen />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/switch-account" element={<SwitchAccount />} />
        <Route path="/products" element={<ProductsIndex />} />
        {/* @ts-expect-error props are done through HOC */}
        <Route path="/products/:id" element={<ProductShow />} />
        {/* @ts-expect-error props are done through HOC */}
        <Route path="/products/:id/add-action" element={<AddActionScreen />} />
        <Route path="/buy-eol-items" element={<BuyEolItemsPage />} />
        <Route path="/buy-eol-items/:id" element={<ViewEOLItemPage />} />
        <Route path="/settings/*" element={<SettingsPage />} />
        <Route path="/edit-profile" element={<EditProfile />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default requiresAuth(App);
