import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { RiPencilFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { accountRoles } from "../../../constants/selectOptions";
import { AccountUser } from "../../../types";
import { ModalProps } from "../../../types/application";
import useConfirmDelete from "../../../utils/withConfirmDelete";
import DefaultButton from "../../Buttons/Default";
import RedButton from "../../Buttons/Red";
import SelectInput from "../../Inputs/Select";
import axios from "../../../plugins/axios";
import { errorParser } from "../../Form/Form.utils";
import SettingsContext from "../../../contexts/settings";

interface UpdateUserModalProps extends ModalProps {
  user: AccountUser;
}

const UpdateUserModal: React.FC<UpdateUserModalProps> = ({
  onCompleted,
  user,
}) => {
  const getDefaults = () => {
    return {
      role: user.role,
    };
  };

  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, setError } = useForm({
    defaultValues: getDefaults(),
  });

  const { getSettings } = useContext(SettingsContext);

  const deleteUser = useConfirmDelete();
  //   const alert = useAlert();

  const submit = async (formData: unknown) => {
    setLoading(true);
    // await switchUserRole(role);
    // setShowUserOptions(null);

    try {
      await axios.put(`settings/users/${user.id}`, formData);
    } catch (error) {
      toast("Failed to update user role", {
        position: "bottom-center",
        type: "error",
        theme: "dark",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      errorParser(error, setError);
    }

    await getSettings();
    setLoading(false);
  };

  const deleteUserById = async () => {
    setLoading(true);

    try {
      await axios.delete(`settings/users/${user.id}`);
    } catch (error) {
      toast("Failed to delete user", {
        position: "bottom-center",
        type: "error",
        theme: "dark",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    await getSettings();
    setLoading(false);
  };

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className="flex justify-end h-6 w-full cursor-pointer"
        onClick={() => onCompleted()}
      >
        <AiOutlineClose className="w-6 text-gray-900" />
      </div>
      <form className="p-8 px-14 pt-3" onSubmit={handleSubmit(submit)}>
        <div>
          <div className="mx-auto flex items-center justify-center h-8 w-8">
            <RiPencilFill size={16} color="black" />
          </div>
          <div className="text-center mt-2">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              Manage User
            </h3>
            <p className="leading-5 font-base text-sm text-gray-400">
              Edit {user.name}&lsquo;s settings below
            </p>

            <div className="mt-8 relative">
              <Controller
                name="role"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }: any): JSX.Element => (
                  <SelectInput
                    value={accountRoles.find((role) => role.value === value)}
                    placeholder="Choose role"
                    onChange={(v: { value: string }) => onChange(v.value)}
                    selectProps={{}}
                    isMulti={false}
                    options={accountRoles}
                  />
                )}
              />
            </div>
          </div>
        </div>
        {/* <div className="flex mt-8">
      <Button
        text="Save Changes"
        type="submit"
        buttonStyle="green"
        loading={false}
        disabled={false}
      />
    </div> */}
        <div className="flex mt-8">
          <DefaultButton
            title="Save Changes"
            type="submit"
            loading={loading}
            disabled={loading}
          />
        </div>
        <div className="flex mt-3">
          <RedButton
            title="Delete user"
            type="button"
            loading={loading}
            disabled={loading}
            onClick={() => deleteUser("DELETE USER", deleteUserById)}
          />
        </div>
      </form>
    </>
  );
};

export default UpdateUserModal;
