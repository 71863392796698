import { Controller } from "react-hook-form";
import { useAuth } from "../../../utils/useAuth";
import TextInput from "../../Inputs/TextInput";
import Form from "../../Form";
import FormFieldErrors from "../../Form/FormFieldErrors";

const EditProfileForm: React.FC<any> = () => {
  const { user, fetchUser } = useAuth();

  const getDefaults = () => {
    return {
      // @ts-ignore
      name: user?.name,
      // @ts-ignore
      email: user?.email,
      // @ts-ignore
      phone: user?.phone,
    };
  };

  const onSubmit = async (responseData: any) => {
    await fetchUser();
  };

  return (
    <Form
      getDefaults={getDefaults}
      onCompleted={onSubmit}
      buttonTitle="Update Details"
      endpoint="user"
      method="put"
      className="w-full md:max-w-2xl"
      formButtonHolderClassName="w-full md:w-1/2"
      render={({ errors, control }) => (
        <>
          <div className="mb-4 mt-6">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.name}
                  placeholder="Full name"
                />
              )}
            />

            <FormFieldErrors
              errors={errors}
              field="name"
              requiredMessage="Full name is required"
            />
          </div>

          <div className="mb-4">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.email}
                  placeholder="Email address"
                  type="email"
                />
              )}
            />

            <FormFieldErrors
              errors={errors}
              field="email"
              requiredMessage="Email address is a required field"
            />
          </div>

          <div className="mb-6">
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.phone}
                  placeholder="Enter phone number"
                />
              )}
            />

            <FormFieldErrors errors={errors} field="phone" />
          </div>
        </>
      )}
    />
  );
};

export default EditProfileForm;
