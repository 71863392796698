import FileInput from "../File";

import SelectInput from "../Select";
import TextInput from "../TextInput";

const GhostCertificationField: React.FC<any> = ({
  value,
  onChange,
  field,
  invalid,
  placeholder = "",
}) => {
  const sharedProps = () => {
    return {
      value,
      onChange,
      invalid,
      placeholder,
    };
  };
  switch (field.type) {
    case "text":
    case "textarea":
      return <TextInput {...sharedProps()} type={field.type} />;
    case "multiselect":
      return (
        <SelectInput
          {...sharedProps()}
          options={field.options.map((ind: any) => ({
            label: ind,
            value: ind,
          }))}
          selectProps={{}}
        />
      );
    case "files":
      return <FileInput {...sharedProps()} />;

    default:
      return <div>No matching field</div>;
  }
};

export default GhostCertificationField;
