import { useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

const TableFilterDropdown: React.FC<any> = ({
  filterComponent,
  filters,
  setFilters,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className="relative  mr-2">
      <div
        className="bg-gray-200 rounded-md w-32 p-3 text-gray-600 text-center hover:bg-gray-300 cursor-pointer hover:text-black flex items-center justify-between text-sm"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        Filter
        {showDropdown ? <HiChevronUp size={20} /> : <HiChevronDown size={20} />}
      </div>

      {showDropdown && <>{filterComponent({ filters, setFilters })}</>}
    </div>
  );
};

export default TableFilterDropdown;
