import React from "react";

interface SettingsPageHeaderProps {
  title: string;
  subtitle: string;
}

const SettingsPageHeader: React.FC<SettingsPageHeaderProps> = ({
  title,
  subtitle,
}) => {
  return (
    <div className="max-w-5xl mx-auto my-6">
      <h2 className="text-xl font-medium">{title}</h2>
      <h4 className="text-md">{subtitle}</h4>
    </div>
  );
};

export default SettingsPageHeader;
