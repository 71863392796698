import SettingsTabDetails from "../components/SettingsTabs/Details";
import SettingsTabIndustriesAndCertifications from "../components/SettingsTabs/IndustriesAndCertifications";
import PaymentDetailsManagement from "../components/SettingsTabs/Payments";
import SettingsTabPoliciesAndTerms from "../components/SettingsTabs/PoliciesAndTerms";
import SettingsTabRolesAndPermissions from "../components/SettingsTabs/RolesAndPermissions";
import SettingsTabUsers from "../components/SettingsTabs/Users";

export const settingsRoutes = [
  {
    title: "General",
    children: [
      {
        title: "Details",
        path: "",
        subtitle: "Edit your manufacturer details here",
        Component: SettingsTabDetails,
      },
      {
        title: "Policies & Terms",
        path: "/policies-and-terms",
        subtitle:
          "Manage your terms of use and policies that will be available publicly to users.",
        Component: SettingsTabPoliciesAndTerms,
      },
      {
        title: "Industries & Certifications",
        path: "/industries-and-certifications",
        subtitle:
          "Manage your terms of use and policies that will be available publicly to users.",
        Component: SettingsTabIndustriesAndCertifications,
      },
      {
        title: "Company Profile",
        path: "/company-profile",
        subtitle: "How your company will look to users on the store",
        Component: null,
      },
    ],
  },
  {
    title: "Users & Access",
    children: [
      {
        title: "Roles & Permissions",
        path: "/roles-and-permissions",
        subtitle: "Create roles with specified permissions",
        Component: SettingsTabRolesAndPermissions,
      },
      {
        title: "Users",
        path: "/users",
        subtitle: "Manage account users and access here",
        Component: SettingsTabUsers,
      },
    ],
  },
  {
    title: "Finance",
    children: [
      {
        title: "Payments Settings",
        path: "/payment-settings",
        subtitle: "Manage your finance and payout details here.",
        Component: PaymentDetailsManagement,
      },
    ],
  },
  {
    title: "Developer",
    children: [
      {
        title: "Settings",
        path: "/developer-settings",
        subtitle: "",
        Component: null,
      },
      {
        title: "Integrations",
        path: "/integrations",
        subtitle: "",
        Component: null,
      },
    ],
  },
];

export default {};
