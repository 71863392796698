import { useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import axios from "../../plugins/axios";
import DefaultButton from "../../components/Buttons/Default";
import RedButton from "../../components/Buttons/Red";
import Modal from "../../components/Modal";
import MakeOfferOnEOLItemModel from "../../components/Modals/MakeOfferOnEOLITemModal";
import { useAuth } from "../../utils/useAuth";
import { handlesSingularModel } from "../../utils/usesSingularModel";

const ViewEOLItemPage: React.FC<any> = ({ item, refresh }) => {
  const [showCreate, setShowCreate] = useState(false);
  const { account } = useAuth();

  const rescindOffer = async () => {
    try {
      await axios.delete(`/manufacturers/eol-items/${item.id}/offers`);
      refresh();
    } catch (error) {}
  };
  return (
    <div className="w-full">
      <div className="w-full flex items-center justify-between h-20 p-4 border-b bg-gray-100">
        <div className="flex items-center">
          <Link
            to="/buy-eol-items"
            className="text-green-600 hover:text-green-800 mr-4"
          >
            <IoMdArrowBack size={26} />
          </Link>
          <h1 className="font-medium text-xl text-gray-700">
            {item.product?.name}
          </h1>
        </div>

        <div className="w-1/2" />
        <div>
          {item.offers.find(
            (off: any) => off.manufacturer_id === account.id
          ) ? (
            <RedButton title="Rescind offer" onClick={() => rescindOffer()} />
          ) : (
            <DefaultButton
              title="Make offer"
              onClick={() => setShowCreate(true)}
            />
          )}
        </div>
      </div>

      <div className="w-full grid grid-cols-2 h-full text-gray-500">
        <div className="p-10 ">
          <h3 className="text-gray-800 font-medium text-lg">Make: </h3>
          {item.product.make}
          <h3 className="text-gray-800 font-medium text-lg mt-4">Model: </h3>
          {item.product.model}
          <h3 className="text-gray-800 font-medium text-lg mt-4">
            Product details (Submitted by user):{" "}
          </h3>
          {item.product_details}
          <h3 className="text-gray-800 font-medium text-lg mt-4">
            Serial Number:{" "}
          </h3>
          {item.serial_number}
          <h3 className="text-gray-800 font-medium text-lg mt-4">
            Desired Price:{" "}
          </h3>
          £{item.price ?? "-"}
        </div>
        <div className="border-l border-gray-200 p-10">
          <h3 className="text-gray-800 font-medium text-lg">
            Offers{" "}
            <span className="text-gray-500 font-normal">
              (Sorted by highest amount desc)
            </span>
            :{" "}
          </h3>

          {item.offers.length === 0 && (
            <p className="w-1/2 text-sm font-light">
              This item has not received any offers yet, click the make an offer
              button above to create an offer.
            </p>
          )}

          {item.offers.map((offer: any) => (
            <div
              key={offer.manufacturer_id}
              className="bg-white shadow my-4 p-6 rounded-md text-gray-600"
            >
              <b>Remanufacturer:</b> {offer.manufacturer_name}, <br />
              <b>Amount:</b> £{offer.offer}
            </div>
          ))}
        </div>
      </div>

      <Modal
        shown={showCreate}
        onClose={() => setShowCreate(false)}
        dismissible
      >
        <MakeOfferOnEOLItemModel
          item={item}
          onComplete={() => {
            refresh();
            setShowCreate(false);
          }}
        />
      </Modal>
    </div>
  );
};
export default handlesSingularModel(
  ViewEOLItemPage,
  "/manufacturers/eol-items"
);
