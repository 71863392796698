import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import IndustryVerificationForm from "../../Forms/IndustryVerification";
import { IndustryType } from "../../../types";
import FormBuilderDisplay from "../../FormBuilderDisplay";
import SettingsContext from "../../../contexts/settings";

const IndustryViewManageScreen: React.FC<any> = ({
  match: {
    params: { industrySlug },
  },
}) => {
  const {
    settings: { industries },
    availableIndustries,
  } = useContext(SettingsContext);

  const currentIndustry = useMemo(() => {
    const apiInd = availableIndustries.find((ind) => ind.slug === industrySlug);
    const manInd = industries.find((ind) => ind.slug === industrySlug);
    return {
      ...apiInd,
      ...(manInd
        ? {
            verified: manInd.verified,
            submitted: true,
            certifications: manInd?.certifications,
          }
        : {
            verified: false,
            submitted: false,
          }),
    };
  }, [industrySlug, industries, availableIndustries]);

  /**
   *
   *
   * There will be 3 different states for this
   *
   * 1. industry certifications have been submitted and approved, user can view or edit these details
   * 2. industry has been submitted for approval, user can view and edit certain fields or cancel the request
   * 3. user is filling out the form to be approved for this new industry
   *
   *
   * @TODO figure out how to handle edits and make sure they get approved without blocking the company from selling products using existing data.
   */
  return (
    <div className="max-w-5xl mx-auto my-6">
      <Link
        to="/settings/industries-and-certifications"
        className="flex items-center text-green-800 font-bold text-sm mb-6"
      >
        <BiArrowBack className="mr-2" size={15} /> Back to list
      </Link>
      {currentIndustry.submitted ? (
        <FormBuilderDisplay
          schema={currentIndustry.registration_rules}
          values={currentIndustry?.certifications}
        />
      ) : (
        <IndustryVerificationForm industry={currentIndustry as IndustryType} />
      )}
    </div>
  );
};

export default IndustryViewManageScreen;
