import axios from "axios";
import { BsArrowLeft } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DropdownButton from "../../../components/DropdownButton";
import EditProductItemForm from "../../../components/Forms/EditProductItemForm";
import ProductItemAuditLog from "../../../components/ProductItemAuditLog";
import { ProductItem } from "../../../types";
import { handlesSingularModel } from "../../../utils/usesSingularModel";

const ActionDropdown: React.FC<{ title: string; color?: string }> = ({
  title,
  color = "black",
}) => {
  return (
    <div
      className="w-48 p-3 hover:bg-gray-100 cursor-pointer"
      style={{ color }}
    >
      {title}
    </div>
  );
};

const ProductShow: React.FC<{ item: ProductItem; refresh: () => void }> = ({
  item,
  refresh,
}) => {
  const navigate = useNavigate();

  const markProductAsActive = async () => {
    try {
      await axios.post(`manufacturers/items/${item.id}/task`, {
        type: "make_active",
      });
      await refresh();
    } catch (error) {
      toast(
        "Failed to make product active, please make sure all fields are filled!"
      );
    }
  };

  return (
    <div className="p-4 w-full">
      <div className="p-3 mr-auto">
        <Link
          to="/products"
          className="flex items-center mb-6 font-medium text-sm"
        >
          <BsArrowLeft className="mr-2 text-lg" /> Back to products
        </Link>
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-medium mb-2">{item.product_name}</h1>

          <div className="w-56">
            <DropdownButton
              title="Actions"
              items={[
                item.status !== "active"
                  ? {
                      body: (
                        <ActionDropdown title="Make Active" color="green" />
                      ),
                      onClick: () => markProductAsActive(),
                    }
                  : {
                      body: (
                        <ActionDropdown title="Make Inactive" color="red" />
                      ),
                      onClick: () => {},
                    },
                {
                  body: <ActionDropdown title="Mark As Sold" color="#4480cb" />,
                  onClick: () => {},
                },
                {
                  body: <ActionDropdown title="Record Data" />,
                  onClick: () => {
                    navigate(`/products/${item.id}/add-action`);
                  },
                },
                {
                  body: <ActionDropdown title="Delete item" color="red" />,
                  onClick: () => {},
                },
              ]}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <EditProductItemForm item={item} refresh={refresh} />
          <ProductItemAuditLog item={item} />
        </div>
      </div>
    </div>
  );
};

export default handlesSingularModel(ProductShow, "/manufacturers/items");
