export const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    // borderBottom: "1px dotted pink",
    // color: state.isSelected ? "red" : "blue",
    // padding: 20,
    backgroundColor: "white",
    zIndex: 9999,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    // width: 200,
    backgroundColor: "white",
    display: "flex",
    padding: "3px",
    borderRadius: "0.25rem",
    zIndex: 9999,
    boxShadow:
      "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
  }),
  singleValue: (provided: any, state: any) => {
    // const opacity = state.isDisabled ? 0.5 : 1;
    // const transition = "opacity 300ms";

    return { ...provided };
  },
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
};
