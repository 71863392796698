import React, { useState } from "react";
import "draft-js/dist/Draft.css";
import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  RawDraftContentState,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import wysiwigToolbarConfig from "./toolbar";

interface WysiwygInputProps {
  value: RawDraftContentState;
  onChange: (value: RawDraftContentState) => void;
  invalid?: boolean;
}
const WysiwygInput: React.FC<WysiwygInputProps> = ({
  value,
  onChange,
  invalid = false,
}) => {
  const getInitial = () => {
    try {
      return convertFromRaw(value);
    } catch (error) {
      return EditorState.createEmpty().getCurrentContent();
    }
  };
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(getInitial())
  );

  return (
    <div className="bg-white border border-white rounded-md">
      <Editor
        editorState={editorState}
        onEditorStateChange={(state) => {
          setEditorState(state);
          onChange(convertToRaw(state.getCurrentContent()));
        }}
        toolbar={wysiwigToolbarConfig}
        editorClassName="p-2"
        toolbarClassName="rounded-md overflow-hidden"
      />
    </div>
  );
};

export default WysiwygInput;
