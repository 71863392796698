import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import WhiteButton from "../Buttons/White";

interface DropdownButtonItem {
  body: any;
  onClick: () => void;
}

interface DropdownButtonProps {
  title: string;
  items: DropdownButtonItem[];
}

const DropdownButton: React.FC<DropdownButtonProps> = ({ title, items }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="relative">
      <WhiteButton
        title={
          <p className="flex items-center justify-center">
            {title}
            {!open ? (
              <BsChevronDown className="ml-2 mt-1" />
            ) : (
              <BsChevronUp className="ml-2 mt-1" />
            )}
          </p>
        }
        onClick={() => {
          setOpen(!open);
        }}
      />
      {open && (
        <div className="absolute top-10 mt-1 bg-white right-0 rounded-md shadow-lg">
          {items.map((item, index) => (
            <div
              role="button"
              tabIndex={0}
              key={index}
              onClick={() => {
                setOpen(false);
                item.onClick();
              }}
            >
              {item.body}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default DropdownButton;
