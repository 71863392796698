import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

const TablePagination: React.FC<any> = ({
  from,
  total,
  total_pages,
  current_page,
  per_page,
  setPaging,
}) => {
  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{from}</span> to{" "}
            <span className="font-medium">{from + per_page - 1}</span> of{" "}
            <span className="font-medium">{total}</span> results
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <div
              aria-disabled={current_page === 1}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 cursor-pointer"
              onClick={() => {
                if (current_page > 1) {
                  setPaging(current_page - 1);
                }
              }}
            >
              <span className="sr-only">Previous</span>
              <HiChevronLeft className="h-5 w-5" aria-hidden="true" />
            </div>

            {Array.from(Array(total_pages)).map((page, index) => (
              <div
                onClick={() => {
                  if (current_page !== index + 1) {
                    setPaging(index + 1);
                  }
                }}
                key={index}
                className={
                  current_page === index + 1
                    ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                    : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
                }
              >
                {index + 1}
              </div>
            ))}
            <div
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
              onClick={() => {
                if (total_pages > current_page) {
                  setPaging(current_page + 1);
                }
              }}
            >
              <span className="sr-only">Next</span>
              <HiChevronRight className="h-5 w-5" aria-hidden="true" />
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TablePagination;
