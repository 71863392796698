const wysiwigToolbarConfig = {
  options: [
    "inline",
    // "blockType",
    // "fontSize",
    // "list",
    // "textAlign",
    // "colorPicker",
    // "link",
    // "embedded",
    // "emoji",
    // "image",
    // "remove",
    // "history",
  ],
  //   inline: { inDropdown: true },
  //   list: { inDropdown: true },
  //   textAlign: { inDropdown: true },
  //   link: { inDropdown: true },
  //   history: { inDropdown: true },
  //   fontFamily:
};

export default wysiwigToolbarConfig;
