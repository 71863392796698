import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "../../plugins/axios";
import DefaultButton from "../Buttons/Default";
import CurrencyInput from "../Inputs/CurrencyInput";
import FieldHolder from "../Inputs/FieldHolder";
import TextInput from "../Inputs/TextInput";

const MakeOfferOnEOLItemModel: React.FC<any> = ({ item, onComplete }) => {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      comment: "",
      offer_price: 0,
    },
  });
  const onSubmit = async (formData: any) => {
    setLoading(true);
    try {
      await axios.post(`/manufacturers/eol-items/${item.id}/offers`, formData);
      onComplete();
    } catch (error) {}

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-5">
        <h3 className="text-2xl font-medium text-gray-800 mb-2">Make offer</h3>
        <p className="text-gray-600 text-sm">
          Enter an amount and an optional comment bellow for the item, only the
          seller will be able to see your comment, they will also be able to see
          the End of Life purchase policies you have setup in the admin area.
        </p>
      </div>

      <FieldHolder
        {...{
          control,
          errors,
          label: "Amount (£)",
          id: "offer_price",
          required: true,
        }}
        field={({ field: { onChange, value } }: any): JSX.Element => (
          <CurrencyInput
            value={value}
            onChange={onChange}
            invalid={!!errors.details}
            placeholder="Input your offer amount"
          />
        )}
      />
      {/* <FieldHolder
        {...{
          control,
          errors,
          label: "Comment",
          id: "comment",
          required: false,
        }}
        field={({ field: { onChange, value } }: any): JSX.Element => (
          <TextInput
            value={value}
            onChange={onChange}
            invalid={!!errors.details}
            placeholder="You can add a comment for the seller here."
            type="textarea"
          />
        )}
      /> */}
      <div className="flex items-center justify-between mt-8 w-full">
        <DefaultButton
          title={loading ? "Loading..." : "Submit Offer"}
          type="submit"
        />
      </div>
    </form>
  );
};

export default MakeOfferOnEOLItemModel;
