import { errorStyle } from "../../../constants/styles";

interface FormFieldErrorsProps {
  errors: any;
  field: string;
  requiredMessage?: string;
}

const FormFieldErrors: React.FC<FormFieldErrorsProps> = ({
  errors,
  field,
  requiredMessage = "",
}) => {
  return (
    <>
      {errors[field] && errors[field].type === "required" && (
        <span className={errorStyle}>
          {requiredMessage ?? `The ${field} is required to continue`}
        </span>
      )}
      {errors[field] && errors[field].type === "api" && (
        <span className={errorStyle}>{errors[field].message}</span>
      )}
    </>
  );
};

export default FormFieldErrors;
