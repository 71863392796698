import { useAuth } from "../../utils/useAuth";

type ChooseAccountProps = {
  setType: Function;
};

const ChooseAccount = ({ setType }: ChooseAccountProps) => {
  const { user, switchAccount } = useAuth();
  return (
    <div className="flex items-center justify-center h-full flex-col  max-w-md mx-auto ">
      {user &&
        user.manufacturers &&
        user.manufacturers.map((man: any, index: number) => (
          <div
            key={index}
            onClick={() => {
              if (man.verified) {
                switchAccount(man.id);
              }
            }}
            className={`${
              man.verified
                ? "hover:border-green-600 cursor-pointer"
                : "cursor-blocked"
            } border-2 w-full rounded-md text-center p-4 mb-4`}
          >
            <h3>{man.name}</h3>
            <h5>{man.company_number}</h5>
          </div>
        ))}
      <div>
        {user && user.manufacturers && user.manufacturers.length === 0 ? (
          <p className="w-1/2 text-center text-gray-700 font-medium mb-2">
            You are not a part of any organizations. Please select an option
            below to gain access to the portal.
          </p>
        ) : (
          <p className="w-full text-center text-gray-700 font-medium my-2">
            OR
          </p>
        )}
        <div className="grid grid-cols-2 gap-4">
          <div
            onClick={(e) => {
              e.preventDefault();
              setType("create");
            }}
            className=" border-2 border-gray-500 p-4 rounded-md text-center text-gray-500 flex items-center justify-center hover:border-green-600 cursor-pointer hover:text-green-800"
          >
            Register new organization
          </div>
          <div
            onClick={(e) => {
              e.preventDefault();
              setType("join");
            }}
            className="block border-2 border-gray-500 p-4 rounded-md text-center text-gray-500 flex items-center justify-center hover:border-green-600 cursor-pointer hover:text-green-800"
          >
            Join an existing organization
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseAccount;
