import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const WeeklyDashboardGraph: React.FC<any> = ({ data }) => {
  return (
    <AreaChart
      width={1200}
      height={400}
      data={data}
      margin={{ top: 20, right: 80, left: 0, bottom: 5 }}
    >
      <defs>
        <linearGradient id="MyGradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="rgba(0, 136, 254, 0.8)" />
          <stop offset="95%" stopColor="rgba(0, 136, 254, 0)" />
        </linearGradient>
      </defs>
      <Legend layout="vertical" align="right" verticalAlign="top" />

      <XAxis dataKey="day" overlineThickness={4}>
        {/* <Label position="insideBottom" value="province" /> */}
      </XAxis>
      <CartesianGrid stroke="#f5f5f5" />
      <YAxis tickCount={7} tickFormatter={(value) => `£${value}`} />
      <Tooltip label="" formatter={(value: string) => `£${value}`} />
      <Area
        type="monotone"
        dataKey="Last Week"
        stroke="#B85BD9"
        strokeWidth="4"
        strokeOpacity="0.5"
        fillOpacity="0.5"
        fill="transparent"
      />
      <Area
        type="monotone"
        dataKey="This Week"
        stroke="#57B7D4"
        strokeWidth="4"
        strokeOpacity="0.8"
        fill="transparent"
      />
    </AreaChart>
  );
};

export default WeeklyDashboardGraph;
