/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState, useEffect, useContext, createContext } from "react";
import cookie from "cookie";
import axios from "../plugins/axios";
import { User } from "../types";

interface UseAuthContext {
  user: User | null;
  account: any;
  login: (email: string, password: string) => Promise<void>;
  register: (
    name: string,
    email: string,
    password: string,
    password_confirmation: string
  ) => Promise<void>;
  verifyEmail: (code: string) => Promise<void>;
  switchAccount: (id: number | null) => void;
  sendPasswordResetEmail: (email: string) => void;
  logout: () => void;
  fetchUser: () => void;
}

export const AuthContext = createContext<UseAuthContext>({
  user: null,
  account: null,
  login: async () => {},
  register: async () => {},
  verifyEmail: async () => {},
  switchAccount: () => {},
  sendPasswordResetEmail: () => {},
  fetchUser: async () => {},
  logout: async () => {},
});

export const useAuth = () => useContext<UseAuthContext>(AuthContext);

function useProvideAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [account, setAccount] = useState(null);

  const setupXsrf = async () => {
    if (cookie.parse(document.cookie)["X-XSRF-TOKEN"] || false) return;

    await axios.get("auth/csrf-cookie", {
      withCredentials: true,
    });
  };

  const fetchUser = async () => {
    if (cookie.parse(document.cookie)["X-XSRF-TOKEN"] || false) return;

    try {
      const { data } = await axios.get("user");
      setUser(data.data);
      try {
        if (
          data.data.manufacturers.length === 1 &&
          data.data.manufacturers[0].verified
        )
          setAccount(data.data.manufacturers[0]);
      } catch (error) {}
    } catch (error) {
      setUser(null);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []); // eslint-disable-line

  const login = async (email: string, password: string) => {
    await setupXsrf();
    return axios.post("auth/login", { email, password }).then(({ data }) => {
      fetchUser();
      return data;
    });
  };

  const register = async (
    name: string,
    email: string,
    password: string,
    password_confirmation: string
  ) => {
    await setupXsrf();

    const { data } = await axios.post("auth/register", {
      name,
      email,
      password,
      password_confirmation,
    });

    await fetchUser();

    return data;
  };

  const verifyEmail = async (code: string) => {
    return axios.post("auth/verify-email", { code }).then(({ data }) => {
      fetchUser();
      return data;
    });
  };

  const logout = () =>
    axios.post("auth/logout").then(({ data }) => {
      setUser(null);
      return data;
    });

  const switchAccount = async (id: number | null) => {
    await axios.post("account/scope", { account_id: id });
    if (id == null) {
      setAccount(null);
      return;
    }
    if (user == null) return;

    try {
      const newAccount = user.manufacturers.find((man: any) => man.id === id);
      if (newAccount) setAccount(newAccount);
    } catch (error) {}
  };
  const sendPasswordResetEmail = (email: string) =>
    axios.post("auth/forgot-password", { email });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const confirmPasswordReset = (code: string, password: string) => {};

  return {
    user,
    account,
    login,
    register,
    logout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    setupXsrf,
    switchAccount,
    fetchUser,
    verifyEmail,
  };
}

export const ProvideAuth: React.FC = ({ children }: any) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
