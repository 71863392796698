type RegisterState = {
  isLoading: boolean;
  error?: string;
  validSteps: Array<string>;
  invalidSteps: Array<string>;
};

type RegisterAction = {
  type: string;
  payload: any;
};

export type RegisterStep = {
  id: string;
  title: string;
  fields: Array<string>;
};

export const registerInitialState = {
  isLoading: false,
  error: undefined,
  validSteps: [],
  invalidSteps: [],
};

export const registrationSteps: Array<RegisterStep> = [
  {
    id: "personal_details",
    title: "Personal Details",
    fields: ["firstname", "surname", "role", "phone_number"],
  },
  {
    id: "company_details",
    title: "Company Details",
    fields: ["company_name", "company_number", "company_vat"],
  },
  {
    id: "location",
    title: "Location",
    fields: [
      "address_building_name",
      "address_street",
      "address_city_town",
      "address_county",
      "address_postcode",
    ],
  },
  {
    id: "team",
    title: "Team",
    fields: [],
  },
  {
    id: "industry",
    title: "Industry",
    fields: ["industries"],
  },
  {
    id: "certifications",
    title: "Certifications & QA",
    fields: ["general_accreditation", "automotive.*"],
  },
  {
    id: "terms",
    title: "Terms & Privacy",
    fields: [],
  },
];

export function stepIsValid(step: string, state: RegisterState): string {
  if (state.invalidSteps.includes(step)) return "invalid";
  if (state.validSteps.includes(step)) return "valid";
  return "incomplete";
}

export function registerReducer(
  state: RegisterState,
  action: RegisterAction
): RegisterState {
  switch (action.type) {
    case "setLoading":
      return { ...state, isLoading: action.payload };
    case "setValid":
      return { ...state, validSteps: [...state.validSteps, action.payload] };
    case "setInvalid":
      return {
        ...state,
        invalidSteps: [...state.invalidSteps, action.payload],
      };
    case "removeValid":
      return {
        ...state,
        validSteps: state.validSteps.filter((vs) => vs !== action.payload),
      };
    case "removeInvalid":
      return {
        ...state,
        invalidSteps: state.invalidSteps.filter((vs) => vs !== action.payload),
      };
    default:
      throw new Error();
  }
}
