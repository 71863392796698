import { Controller } from "react-hook-form";
import Form from "../../Form";
import FormFieldErrors from "../../Form/FormFieldErrors";
import CurrencyInput from "../../Inputs/CurrencyInput";
import TextInput from "../../Inputs/TextInput";

const EditProductItemForm: React.FC<any> = ({ item, refresh }) => {
  const getDefaults = () => {
    return {
      serial_number: item.serial_number,
      price: item.price,
      delivery_price: item.delivery_price,
      est_lead_time: item.est_lead_time,
    };
  };

  const onSubmit = async (responseData: any) => {
    // await fetchUser();
    await refresh();
  };

  return (
    <Form
      getDefaults={getDefaults}
      onCompleted={onSubmit}
      buttonTitle="Update Details"
      endpoint={`manufacturers/items/${item.id}`}
      method="put"
      className="w-full md:max-w-2xl"
      formButtonHolderClassName="w-full md:w-1/2"
      render={({ errors, control }) => (
        <>
          {JSON.stringify(item.product)}
          <div className="mb-4 mt-6">
            <label className="ml-3 text-sm mb-2 block">Serial Number</label>
            <Controller
              name="serial_number"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.serial_number}
                  placeholder="Item serial number"
                  disabled
                />
              )}
            />

            <FormFieldErrors
              errors={errors}
              field="serial_number"
              requiredMessage="Item serial number is required"
            />
          </div>
          <div className="mb-4 mt-6">
            <label className="ml-3 text-sm mb-2 block">Sale Price</label>
            <div className="ml-3 text-xs font-medium text-gray-500 mb-2">
              Suggested: £{item.product_guide_price}
            </div>
            <Controller
              name="price"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <CurrencyInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.price}
                  placeholder="Sale Price"
                />
              )}
            />

            <FormFieldErrors
              errors={errors}
              field="price"
              requiredMessage="Sale Price is required"
            />
          </div>
          <div className="mb-4 mt-6">
            <label className="ml-3 text-sm mb-2 block">Delivery Price</label>
            <Controller
              name="delivery_price"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <CurrencyInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.delivery_price}
                  placeholder="Enter delivery price"
                />
              )}
            />

            <FormFieldErrors
              errors={errors}
              field="delivery_price"
              requiredMessage="Delivery price required"
            />
          </div>

          <div className="mb-4 mt-6">
            <label className="ml-3 text-sm mb-2 block">
              Est Lead Time (Days)
            </label>
            <Controller
              name="est_lead_time"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  type="number"
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.est_lead_time}
                  placeholder="Enter delivery price"
                />
              )}
            />

            <FormFieldErrors
              errors={errors}
              field="est_lead_time"
              requiredMessage="Est lead time is required"
            />
          </div>
        </>
      )}
    />
  );
};

export default EditProductItemForm;
