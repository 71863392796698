import { ComponentType, FunctionComponent } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { useAuth } from "./useAuth";
import LoginScreen from "../pages/auth";
import ForgotPassword from "../pages/auth/forgot-password";
import RegisterScreen from "../pages/auth/register";
import ResetPassword from "../pages/auth/reset-password";
import NoAccountScreen from "../components/NoAccount";
import VerifyEmailScreen from "../pages/auth/verify-email";

export default function requiresAuth<T extends Record<string, unknown>>(
  Component: ComponentType<T>
): FunctionComponent<T> {
  const AppWithAuthenticator: FunctionComponent<T> = (props) => {
    const { user, account } = useAuth();

    if (!user)
      return (
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/*"
            element={
              <>
                <div
                  className="flex items-center justify-center flex-col p-10 text-gray-500 text-xl font-light"
                  style={{ minHeight: "60vh" }}
                >
                  You must be logged in to view this page. <br />
                  <Link
                    to="/"
                    className="font-medium text-gray-900 cursor-pointer hover:text-black"
                  >
                    Login
                  </Link>
                </div>
              </>
            }
          />
        </Routes>
      );
    if (!user.email_verified) return <VerifyEmailScreen />;
    if (!account) return <NoAccountScreen />;

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} />;
  };

  return AppWithAuthenticator;
}
