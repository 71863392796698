import Select from "react-select";
import { customStyles } from "./utils";

type SelectOption = {
  label: string;
  value: string | number;
};

type SelectInputProps = {
  value: any;
  onChange: Function;
  invalid?: boolean;
  label?: string;
  id?: string;
  disabled?: boolean;
  theme?: string;
  placeholder?: string;
  className?: string;
  options: Array<SelectOption>;
  selectProps: any;
  isMulti?: boolean;
};

const SelectInput = ({
  value,
  onChange,
  placeholder = "Choose option",
  className = "",
  options = [],
  selectProps = {},
  isMulti = true,
}: SelectInputProps) => {
  return (
    <Select
      styles={customStyles}
      options={options}
      isMulti={isMulti}
      {...selectProps}
      onChange={onChange}
      value={value}
      className={className}
      placeholder={placeholder}
      menuPortalTarget={document.body}
    />
  );
};

export default SelectInput;
