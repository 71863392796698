import TextInput from "../../Inputs/TextInput";
import RegisterStepTitle from "../RegisterStepTitle";
import RegistrationFieldHolder from "../RegistrationFieldHolder";

const CompanyDetails: React.FC<any> = ({ register, control, errors }) => {
  return (
    <div>
      <RegisterStepTitle
        title="Company / Organization Details"
        subtitle="Please complete the following details for your organization. Fields marked with * are mandatory."
      />

      <RegistrationFieldHolder
        {...{
          control,
          errors,
          label: "Registered Company Name",
          id: "company_name",
          required: true,
        }}
        field={({ field: { onChange, value } }: any): JSX.Element => (
          <TextInput
            value={value}
            onChange={onChange}
            invalid={!!errors.company_name}
            placeholder="ACME Corp LTD"
          />
        )}
      />

      <br />
      <div className="grid grid-flow-col grid-cols-2 gap-8 mb-6">
        <RegistrationFieldHolder
          {...{
            control,
            errors,
            label: "Company Number",
            id: "company_number",
            required: true,
          }}
          field={({ field: { onChange, value } }: any): JSX.Element => (
            <TextInput
              value={value}
              onChange={onChange}
              invalid={!!errors.company_number}
              placeholder="GB123456"
            />
          )}
        />

        <RegistrationFieldHolder
          {...{
            control,
            errors,
            label: "VAT Number",
            id: "company_vat",
            required: true,
          }}
          field={({ field: { onChange, value } }: any): JSX.Element => (
            <TextInput
              value={value}
              onChange={onChange}
              invalid={!!errors.company_vat}
              placeholder="12345678"
            />
          )}
        />
      </div>
    </div>
  );
};

export default CompanyDetails;
