import { Controller } from "react-hook-form";
import { useContext } from "react";
import TextInput from "../../Inputs/TextInput";
import Form from "../../Form";
import FormFieldErrors from "../../Form/FormFieldErrors";
import SettingsContext from "../../../contexts/settings";

const EditManufacturerDetailsForm: React.FC = () => {
  const { settings, getSettings } = useContext(SettingsContext);

  const getDefaults = () => {
    return {
      logo: settings.details.logo,
      name: settings.details.name,
      summary: settings.details.summary,
    };
  };

  const onSubmit = async () => {
    await getSettings();
  };

  return (
    <Form
      getDefaults={getDefaults}
      onCompleted={onSubmit}
      buttonTitle="Update Details"
      endpoint="manufacturers/settings/details"
      method="put"
      className="w-full md:max-w-2xl"
      formButtonHolderClassName="w-full md:w-1/2"
      render={({ errors, control }) => (
        <>
          <div className="mb-4 mt-6">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.name}
                  placeholder="Company Name"
                />
              )}
            />

            <FormFieldErrors
              errors={errors}
              field="name"
              requiredMessage="Company name is required"
            />
          </div>

          <div className="mb-4 mt-6">
            <Controller
              name="summary"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.summary}
                  placeholder="Company Summary"
                />
              )}
            />

            <FormFieldErrors
              errors={errors}
              field="summary"
              requiredMessage="Summary Field is required"
            />
          </div>
        </>
      )}
    />
  );
};

export default EditManufacturerDetailsForm;
