import React from "react";

interface ModalProps {
  shown?: boolean;
  children: any;
  onClose?: () => void;
  dismissible?: boolean;
  contentSize?: string;
}

export interface SingleModalProps {
  onClose: () => void;
}

const Modal = ({
  shown = false,
  children,
  onClose = () => {},
  dismissible = false,
  contentSize = "sm:max-w-xl sm:w-full",
}: ModalProps) => {
  const bgClick = (e: any) => {
    if (dismissible) {
      onClose();
    }
  };

  const handleClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div onClick={bgClick}>
      {shown && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-black bg-opacity-25" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />
            &#8203;
            <div
              className={`inline-block align-bottom bg-gray-50 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${contentSize} sm:p-6`}
              onClick={handleClick}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
