import React from "react";
import { ButtonProps, buttonRenderer } from "../Buttons.utils";

const DefaultButton: React.FC<ButtonProps> = (props) => {
  return buttonRenderer(
    props,
    "bg-green-600 text-white hover:bg-green-700 hover:disabled:bg-green-600"
  );
};

export default DefaultButton;
