import { Link, useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { GrView } from "react-icons/gr";
import axios from "../../../plugins/axios";
import { useAuth } from "../../../utils/useAuth";
import DefaultButton from "../../Buttons/Default";
import SettingsContext from "../../../contexts/settings";

const SettingsTabPoliciesAndTerms: React.FC = () => {
  const navigate = useNavigate();
  const {
    settings: { documents },
    getSettings,
  } = useContext(SettingsContext);
  const { account } = useAuth();

  const deleteDocument = async (id: number) => {
    try {
      await axios.delete(`/manufacturers/documents/${id}`);
      getSettings();
    } catch (error) {}
  };
  return (
    <div>
      {documents.map((document) => (
        <div
          key={document.id}
          className="bg-white rounded-xl shadow-sm p-3 flex justify-between items-center mb-5"
        >
          <div>{document.title}</div>
          <div className="flex">
            <Link
              to={`/settings/policies-and-terms/${document.id}`}
              className="cursor-pointer text-gray-600 hover:text-black mr-2"
            >
              <AiOutlineEdit size={26} />
            </Link>
            <div
              role="button"
              tabIndex={0}
              onClick={() => {
                if (typeof window !== undefined) {
                  // @ts-expect-error react hates the window
                  window
                    ?.open(
                      `${process.env.REACT_APP_STOREFRONT_URL}/manufacturers/${account.id}/${document.id}`,
                      "_blank"
                    )
                    .focus();
                }
              }}
              className="cursor-pointer text-gray-600 hover:text-blue-500"
            >
              <GrView size={24} />
            </div>

            <div
              role="button"
              tabIndex={0}
              onClick={() => {
                if (
                  // eslint-disable-next-line
                  confirm(
                    "Are you sure you want to delete this? This action cannot be undone"
                  )
                ) {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  deleteDocument(document.id!);
                }
              }}
              className="cursor-pointer text-gray-600 hover:text-red-600 ml-3"
            >
              <BsTrash size={24} />
            </div>
          </div>
        </div>
      ))}
      <div className="max-w-xs w-1/4 my-4">
        <DefaultButton
          title="Create document"
          onClick={() => navigate("/settings/policies-and-terms/create")}
        />
      </div>
    </div>
  );
};

export default SettingsTabPoliciesAndTerms;
