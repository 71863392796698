import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { errorStyle } from "../../constants/styles";
import TextInput from "../Inputs/TextInput";
import axios from "../../plugins/axios";
import { errorParser } from "../Form/Form.utils";
import DefaultButton from "../Buttons/Default";

const JoinAccountScreen: React.FC<any> = () => {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      invite_token: "",
    },
    // shouldUnregister: false,
  });

  const onFinish = async (values: any) => {
    setLoading(true);

    try {
      await axios.post("auth/accept-invite", values);
      //   await fetchUser();
      //   history.push(`/`);

      //   window.localStorage.setItem("token", accessToken);
      //   loginSuccess();

      //   setTimeout(() => {
      //     history.push(`/choose-project`);
      //   }, 400);
    } catch (error) {
      errorParser(error, setError);
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="flex bg-gray-100">
        <div className="min-h-screen flex justify-between items-center w-full">
          <div className="min-h-screen flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8 w-full">
            <form onSubmit={handleSubmit(onFinish)} className="w-full max-w-sm">
              <p className="text-md font-base text-gray-450 mb-10 text-center">
                To join an existing organization on Remade Market you will need
                to ask your account admin to invite you to the team, you will be
                sent an authorization code that you will need to enter here.
                <br />
                <br />
                If your code/invite expires your account admin will need to hit
                resend in the users settings panel
              </p>

              <div className="mt-6">
                <Controller
                  name="invite_token"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({
                    field: { onChange, value },
                  }: any): JSX.Element => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      invalid={!!errors.invite_token}
                      placeholder="Enter your authorization code here"
                    />
                  )}
                />
              </div>
              {errors.invite_token &&
                errors.invite_token.type === "required" && (
                  <span className={errorStyle}>
                    Authorization token is required
                  </span>
                )}

              {errors.invite_token && errors.invite_token.type === "api" && (
                <span className={errorStyle}>
                  {errors.invite_token.message}
                </span>
              )}

              <div className="mt-8">
                <DefaultButton
                  title="Join now"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinAccountScreen;
