import { Controller } from "react-hook-form";
import { errorStyle } from "../../../../constants/styles";
import { _t } from "../../../../utils/language";
import CheckBoxInput from "../../../Inputs/Checkbox";
import RegisterStepTitle from "../../RegisterStepTitle";
import Privacy from "./Privacy";
import Terms from "./Terms";

const TermsAndPrivacy: React.FC<any> = ({ control, errors }) => {
  return (
    <div>
      <RegisterStepTitle
        title="Terms of use and Privacy Policy"
        subtitle="Please read through and agree to our privacy policy and terms of use in order to continue."
      />

      <h3 className="text-lg mb-2 font-medium text-gray-700">Terms of Use</h3>
      <div className="overflow-auto h-96 bg-white rounded p-4 text-sm leading-7 shadow">
        <Terms />
      </div>
      <div className="mb-10">
        <Controller
          name="terms"
          control={control}
          defaultValue={false}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }: any): JSX.Element => (
            <CheckBoxInput
              id="terms"
              value={value}
              onChange={onChange}
              invalid={!!errors.terms}
              placeholder={`I agree to the ${_t("general.name")} Terms of Use`}
            />
          )}
        />

        {errors.terms && errors.terms.type === "required" && (
          <span className={errorStyle}>You must agree to the terms of use</span>
        )}
        {errors.terms && errors.terms.type === "api" && (
          <span className={errorStyle}>{errors.privacy.message}</span>
        )}
      </div>

      <h3 className="text-lg mb-2 font-medium text-gray-700">Privacy Policy</h3>
      <div className="overflow-auto h-96 bg-white rounded p-4 text-sm leading-7 shadow">
        <Privacy />
      </div>
      <div className="mb-10">
        <Controller
          name="privacy"
          control={control}
          defaultValue={false}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }: any): JSX.Element => (
            <CheckBoxInput
              id="privacy"
              value={value}
              onChange={onChange}
              invalid={!!errors.privacy}
              placeholder={`I agree to the ${_t(
                "general.name"
              )} Privacy Policy`}
            />
          )}
        />
        {errors.privacy && errors.privacy.type === "required" && (
          <span className={errorStyle}>
            You must agree to the privacy policy
          </span>
        )}
        {errors.privacy && errors.privacy.type === "api" && (
          <span className={errorStyle}>{errors.privacy.message}</span>
        )}
      </div>
    </div>
  );
};

export default TermsAndPrivacy;
