import React from "react";
import { ButtonProps, buttonRenderer } from "../Buttons.utils";

const RedButton: React.FC<ButtonProps> = (props) => {
  return buttonRenderer(
    props,
    "bg-red-400 text-white hover:bg-red-500 hover:disabled:bg-red-600"
  );
};

export default RedButton;
