/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */

import * as lang from "../constants/language";

export const _t = (key: any) => {
  const originalKey = key;
  if (typeof key === "string") key = key.split(".");
  return key.reduce(
    (xs: any, x: any) => (xs && xs[x] ? xs[x] : originalKey),
    lang
  );
};
