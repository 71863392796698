import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { customStyles } from "../utils";

export type AsyncSelectInputProps = {
  value: any;
  onChange: Function;
  invalid?: boolean;
  label?: string;
  id?: string;
  disabled?: boolean;
  theme?: string;
  placeholder?: string;
  className?: string;
  selectProps: any;
  isMulti?: boolean;
  creatable?: boolean;
};

const AsyncSelectInput = ({
  value,
  onChange,
  invalid = false,
  placeholder = "Choose option",
  id = "value",
  disabled = false,
  className = "",
  theme = "dark",
  selectProps = {},
  isMulti = true,
  creatable = false,
}: AsyncSelectInputProps) => {
  const Comp = creatable ? AsyncCreatableSelect : AsyncSelect;
  return (
    <Comp
      isMulti={isMulti}
      styles={customStyles}
      {...selectProps}
      onChange={onChange}
      value={value}
      className={className}
      placeholder={placeholder}
      menuPortalTarget={document.body}
    />
  );
};

export default AsyncSelectInput;
