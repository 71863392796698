import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import AddProductItemActionForm from "../../../components/Forms/AddProductItemActionForm";
import { ProductItem } from "../../../types";
import { handlesSingularModel } from "../../../utils/usesSingularModel";

interface AddActionScreenProps {
  item: ProductItem;
}

const AddActionScreen: React.FC<AddActionScreenProps> = ({ item }) => {
  return (
    <div className="p-4 w-full">
      <div className="p-3 mr-auto">
        <Link
          to={`/products/${item.id}`}
          className="flex items-center mb-6 font-medium text-sm"
        >
          <BsArrowLeft className="mr-2 text-lg" /> Back to Product
        </Link>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <AddProductItemActionForm item={item} />
      </div>
    </div>
  );
};

export default handlesSingularModel<AddActionScreenProps>(
  AddActionScreen,
  "/manufacturers/items"
);
