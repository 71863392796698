/* eslint-disable no-case-declarations */
import React from "react";
import FileInput from "../File";
import TextInput from "../TextInput";

interface SingularFieldRenderProps {
  label: string;
  value: string;
  onChange: (val: string) => void;
}

const SingularFieldRender: React.FC<SingularFieldRenderProps> = ({
  label,
  value,
  onChange,
}) => {
  const renderFieldForValue = () => {
    const type = value.split(":")[0];

    switch (type) {
      case "text":
        return (
          <TextInput
            value={value.replace("text:", "")}
            placeholder={`Enter ${label}`}
            // @ts-ignore
            onChange={(e) => onChange(`text:${e.target.value}`)}
          />
        );
      case "file":
        const fileVal = value.replace("file:", "");
        return (
          <FileInput
            value={fileVal !== "" ? [JSON.parse(fileVal)] : []}
            placeholder={`Choose ${label}`}
            type="product_action_attachment"
            onChange={(e: any) => {
              onChange(`file:${JSON.stringify(e[0])}`);
            }}
          />
        );
      default:
        return <div>Invalid Field</div>;
    }
  };
  return (
    <div className="border-b py-3 grid grid-cols-3">
      <div>{label}</div>{" "}
      <div className="col-span-2">{renderFieldForValue()}</div>
    </div>
  );
};

export default SingularFieldRender;
