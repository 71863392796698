const RegistrationSuccessful: React.FC<any> = () => {
  return (
    <div className="flex items-center justify-center w-full h-full flex-col">
      <h1 className="font-medium text-2xl">Registration has been submitted</h1>
      <p className="w-1/2 text-sm font-gray-600 text-center mt-3">
        Your company along with the details that you have submitted has been
        sent to a member of our team for approval. If there are any issues we
        will use the contact details provided to get in touch, if not you will
        receive a confirmation email when your business has been approved.
      </p>
    </div>
  );
};

export default RegistrationSuccessful;
