import { createContext } from "react";
import { IndustryType, ManufacturerSettingsType } from "../types";

interface SettingsContextType {
  settings: ManufacturerSettingsType;
  availablePermissions: Record<string, unknown>;
  availableIndustries: IndustryType[];
  getSettings: () => void;
}
// @ts-expect-error context is weird
const SettingsContext = createContext<SettingsContextType>(null);
export default SettingsContext;
