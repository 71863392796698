import { useContext } from "react";
import { Controller } from "react-hook-form";
import TextInput from "../Inputs/TextInput";
import Form from "../Form";
import FormFieldErrors from "../Form/FormFieldErrors";
import SelectInput from "../Inputs/Select";
import { accountRoles } from "../../constants/selectOptions";
import SettingsContext from "../../contexts/settings";

const InviteUserToAccountForm: React.FC<any> = ({ onCompleted }) => {
  const { getSettings } = useContext(SettingsContext);

  const getDefaults = () => {
    return {
      name: "",
      email: "",
      role: "user",
    };
  };

  const onSubmit = async (responseData: any) => {
    await getSettings();
    onCompleted();
  };

  return (
    <Form
      getDefaults={getDefaults}
      onCompleted={onSubmit}
      buttonTitle="Send Invite"
      endpoint="manufacturers/settings/users"
      method="post"
      className="w-full md:max-w-2xl"
      formButtonHolderClassName="w-full md:w-1/2"
      render={({ errors, control }) => (
        <>
          <div className="mb-5">
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Invite User
            </h3>
            <p className="text-gray-600 text-sm">
              Fill out the details below to invite a new user to your account.
            </p>
          </div>
          <div className="mb-4 mt-6">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.name}
                  placeholder="Enter user full name"
                />
              )}
            />

            <FormFieldErrors
              errors={errors}
              field="name"
              requiredMessage="Full name is required"
            />
          </div>

          <div className="mb-4 mt-6">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.email}
                  type="email"
                  placeholder="Enter email address"
                />
              )}
            />

            <FormFieldErrors
              errors={errors}
              field="email"
              requiredMessage="Email address field is required"
            />
          </div>

          <div className="mb-4 mt-6">
            <Controller
              name="role"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any): JSX.Element => (
                <SelectInput
                  value={accountRoles.find((role) => role.value === value)}
                  placeholder="Choose role"
                  onChange={(v: { value: string }) => onChange(v.value)}
                  selectProps={{}}
                  isMulti={false}
                  options={accountRoles}
                />
              )}
            />

            <FormFieldErrors
              errors={errors}
              field="role"
              requiredMessage="Please select a role for the user"
            />
          </div>
        </>
      )}
    />
  );
};

export default InviteUserToAccountForm;
