/* eslint-disable no-alert */
import { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { Controller } from "react-hook-form";
import { BsPlusCircle } from "react-icons/bs";
import RegisterStepTitle from "../RegisterStepTitle";
import TextInput from "../../Inputs/TextInput";

const Team: React.FC<any> = ({
  register,
  control,
  errors,
  getValues,
  setValue,
}) => {
  const [users, setUsers] = useState<any>([]);

  const addNewUser = () => {
    if (users.length === 0) {
      setUsers([{ ind: 0 }]);
      return;
    }

    const lastIndex = users.length - 1;

    const lastUser = getValues(`team.${lastIndex}`);

    if (!lastUser.full_name || !lastUser.email || !lastUser.role) {
      alert("You need to fill in all user fields before adding a new one.");
      return;
    }

    setUsers([...users, { ind: lastIndex + 1 }]);
  };

  const deleteUserAtIndex = (index: number) => {
    setUsers(users.filter((uu: any, ind: number) => ind === index));
  };
  return (
    <div>
      <RegisterStepTitle
        title="Team Members"
        subtitle="Please add any team members that will need to be added to the account, if they do not already have a Remade Market account they will be sent an invite once the organization has been approved. Fields marked with * are mandatory."
      />

      {users.map((us: any, index: number) => (
        <div className="grid grid-cols-10 gap-2 mb-4" key={index}>
          <Controller
            {...{
              control,
              errors,
              name: `team.${index}.full_name`,
              required: true,
            }}
            render={({ field: { onChange, value } }: any): JSX.Element => (
              <TextInput
                id="full_name"
                value={value}
                onChange={onChange}
                invalid={!!errors.team}
                placeholder="Full Name"
                className="col-span-3"
              />
            )}
          />
          <Controller
            {...{
              control,
              errors,
              name: `team.${index}.email`,
              required: true,
            }}
            render={({ field: { onChange, value } }: any): JSX.Element => (
              <TextInput
                value={value}
                onChange={onChange}
                invalid={!!errors.team}
                placeholder="Email address"
                className="col-span-3"
              />
            )}
          />
          <Controller
            {...{
              control,
              name: `team.${index}.role`,
              required: true,
            }}
            render={({ field: { onChange, value } }: any): JSX.Element => (
              <TextInput
                value={value}
                onChange={onChange}
                invalid={!!errors.role}
                placeholder="Role"
                className="col-span-3"
              />
            )}
          />
          <div
            className="flex items-center justify-center"
            onClick={() => deleteUserAtIndex(index)}
          >
            <AiFillDelete size={25} />
          </div>
        </div>
      ))}
      <div className="w-full mt-10">
        <div
          className="flex items-center justify-center w-10 text-gray-400 hover:text-black cursor-pointer"
          onClick={addNewUser}
        >
          <BsPlusCircle size={25} />
        </div>
      </div>
    </div>
  );
};

export default Team;
