import React from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import DashboardHeader from "../../components/Dashboard/Header";
import Table from "../../components/Table";

const OrdersScreen: React.FC = () => {
  const columns = [
    {
      id: "order_number",
      Header: "Order Number",
      Cell: ({ row }: any) => (
        <Link className="link text-green-800" to={`/orders/${row.id}`}>
          {row.order_number}
        </Link>
      ),
    },
    {
      id: "completed_at",
      Header: "Order Completed",
      accessor: "completed_at",
    },
    {
      id: "total",
      Header: "Order Total",
      accessor: "amount",
      Cell: ({ row }: any) => <span>£{row.amount}</span>,
    },
    {
      id: "status",
      Header: "Status",
      accessor: "status",
      Cell: ({ row }: any) => {
        switch (row.status) {
          case "in_progress":
            return "In Progress";
          default:
            return "-";
        }
      },
    },
  ];

  return (
    <DashboardLayout active="/dashboard/orders">
      <div className="w-full">
        <DashboardHeader active="/dashboard/orders" />
        <Table
          getAllRoute="manufacturers/orders"
          defaultState={{
            columns,
            sortBy: "created_at",
          }}
        />
      </div>
    </DashboardLayout>
  );
};

export default OrdersScreen;
