/**
 *
 * Takes the default Laravel 422 error response and parses it for react from hooks
 *
 * @param {*} response
 * @param {*} setError
 */

import { toast } from "react-toastify";

export const errorParser = (
  {
    response: {
      status,
      data: { message, errors },
    },
  }: any,
  setError: any
) => {
  if (message) {
    toast(message, {
      position: "bottom-center",
      type: "error",
      theme: "dark",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  if (status !== 422) return;

  const keys = Object.keys(errors);

  keys.forEach((key) => {
    setError(key, { type: "api", message: errors[key][0] });
  });
};
