import React, { ChangeEventHandler } from "react";

type CheckBoxInputProps = {
  value: any;
  onChange: ChangeEventHandler;
  invalid?: boolean;
  label?: string;
  id?: string;
  disabled?: boolean;
  theme?: string;
  placeholder?: string;
  className?: string;
  type?: "text" | "textarea";
};

export default function CheckBoxInput({
  value,
  onChange,
  invalid = false,
  placeholder = "Enter text",
  id = "value",
  disabled = false,
  className = "",
}: CheckBoxInputProps) {
  const props = () => {
    return {
      id,
      name: id,
      value: value ?? "",
      onChange,
      disabled,
      className: `form-checkbox h-5 w-5 text-green-600 focus:ring-transparent rounded ${
        invalid ? "ring-red-600" : "ring-transparent"
      } ${className}`,
      placeholder,
    };
  };
  return (
    <label className="inline-flex items-center mt-3 ">
      <input type="checkbox" {...props()} />
      <span className="ml-2 text-gray-700">{placeholder}</span>
    </label>
  );
}
