/* eslint-disable import/no-mutable-exports */
import getSiteShortname from "../utils/locales";
import indiaStrings from "./in";
import ukStrings from "./uk";

const env = getSiteShortname();

let strings = ukStrings;

const map = {
  in: indiaStrings,
};

if (env !== "uk") {
  strings = {
    ...strings,
    ...map[env],
  };
}

export default strings;
