import { useContext, useState } from "react";
import { IoIosSettings } from "react-icons/io";
import SettingsContext from "../../contexts/settings";
import { AccountRole } from "../../types";
import DefaultButton from "../Buttons/Default";
import CreateRoleForm from "../Forms/Roles/CreateRoleForm";
import EditRoleForm from "../Forms/Roles/EditRoleForm";
import Modal from "../Modal";

const SettingsTabRolesAndPermissions: React.FC = () => {
  const [showAddRole, setShowAddRole] = useState(false);
  const [editRole, setEditRole] = useState<AccountRole | null>(null);

  const { settings, availablePermissions } = useContext(SettingsContext);

  return (
    <div>
      <table
        className="table-auto border-separate mr-0 w-full"
        style={{ borderSpacing: "0 1.2rem" }}
      >
        <thead className="opacity-100 bg-opacity-100">
          <tr>
            <td className="text-left text-md leading-4 font-large text-gray-800 tracking-wider opacity-100 bg-opacity-100 pl-6">
              Name
            </td>
            <td className="text-left text-md leading-4 font-large text-gray-800 tracking-wider">
              Permissions
            </td>
            <td className="text-left text-md leading-4 font-large text-gray-800 tracking-wider">
              Users
            </td>
            <td className="px-6 lg:w-10 w-12" width="5%" />
          </tr>
        </thead>
        <tbody className="max-h-124 w-full">
          {settings.roles.map((role: any, index: number) => (
            <tr
              className="bg-gray-200 bg-opacity-50 mb-6 h-12 pl-6 overflow-hidden w-full"
              key={index}
            >
              <td className="whitespace-no-wrap text-sm leading-5 font-regular text-black opacity-75 pl-6">
                {role.name}
              </td>
              <td className="whitespace-no-wrap text-sm font-light leading-5 text-black opacity-50">
                {role.permissions.join(", ")}
              </td>
              <td className="whitespace-no-wrap text-sm font-light leading-5 text-black opacity-50 ">
                -
              </td>
              <td width="5%" className="">
                <button
                  type="button"
                  className="block cursor-pointer text-gray-800 hover:text-black"
                  onClick={() => setEditRole(role)}
                >
                  <IoIosSettings size={30} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="w-full flex justify-between items-center mt-4">
        <div />
        <div>
          <DefaultButton
            title="Add Role"
            onClick={() => setShowAddRole(true)}
          />
        </div>
      </div>
      <div className="border-t mt-6 pt-4">
        <h3 className="text-xl font-medium">Descriptions:</h3>
        {Object.keys(availablePermissions).map((permKey: string) => (
          <div className="my-2">
            <span className="font-medium text-lg">{permKey}</span>:{" "}
            {availablePermissions[permKey]}
          </div>
        ))}
      </div>
      <Modal
        shown={showAddRole}
        onClose={() => setShowAddRole(false)}
        dismissible
      >
        <CreateRoleForm
          onCompleted={() => {
            setShowAddRole(false);
          }}
        />
      </Modal>
      <Modal
        shown={editRole !== null}
        onClose={() => setEditRole(null)}
        dismissible
      >
        <EditRoleForm
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          role={editRole!}
          onCompleted={() => {
            setEditRole(null);
          }}
        />
      </Modal>
    </div>
  );
};

export default SettingsTabRolesAndPermissions;
