import { Link } from "react-router-dom";
import Badge from "../../Badge";

interface HeaderLinkProps {
  title: string;
  path: string;
  active: string;
  badgeCount?: number;
}
const HeaderLink: React.FC<HeaderLinkProps> = ({
  title,
  path,
  active,
  badgeCount = 0,
}) => {
  return (
    <Link
      className={`${
        active === path ? "text-black" : "text-gray-500"
      } mx-3 font-medium text-sm`}
      to={path}
    >
      <Badge count={badgeCount}>{title}</Badge>
    </Link>
  );
};

export default HeaderLink;
