import React, { useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { ImArrowRight2 } from "react-icons/im";
import TextInput from "../TextInput";
import SingularFieldRender from "./SingularFieldRender";
import SelectInput from "../Select";

interface RepeaterFieldProps {
  value: {
    [key: string]: string;
  };
  onChange: (data: object) => void;
  invalid: boolean;
  placeholder: string;
}

const fieldOptions = [
  {
    label: "Text",
    value: "text",
  },
  {
    label: "File",
    value: "file",
  },
];

const RepeaterField: React.FC<RepeaterFieldProps> = ({
  value,
  placeholder,
  onChange,
  invalid,
}) => {
  const [add, showAdd] = useState(true);
  const [newName, setNewName] = useState("");
  const [newType, setNewType] = useState("text");
  const [fieldError, setFieldError] = useState(false);

  const addItemSubmit = () => {
    if (newName === "" || newName in value) {
      setFieldError(true);
    } else {
      setFieldError(false);

      onChange({ ...value, ...{ [newName]: `${newType}:` } });
      setNewName("");
      setNewType("text");
      showAdd(false);
    }
  };

  return (
    <div>
      {Object.keys(value).map((objectKey) => (
        <SingularFieldRender
          label={objectKey}
          value={value[objectKey]}
          onChange={(v) => {
            onChange({ ...value, ...{ [objectKey]: v } });
          }}
        />
      ))}

      {add && (
        <div>
          <div className="grid grid-cols-6 gap-2">
            <div className="col-span-3">
              <TextInput
                value={newName}
                placeholder="Field title"
                // @ts-ignore
                onChange={(e) => setNewName(e.target.value)}
              />
            </div>
            <div className="col-span-2">
              <SelectInput
                options={fieldOptions}
                isMulti={false}
                selectProps={{}}
                value={fieldOptions.find((df) => df.value === newType)}
                onChange={(v: any) => setNewType(v.value)}
              />
            </div>
            <div
              className="flex items-center justify-center bg-green-600 text-white rounded-md shadow cursor-pointer hover:bg-green-800"
              onClick={addItemSubmit}
            >
              <ImArrowRight2 />
            </div>
          </div>
          {fieldError && (
            <span className="text-xs text-red-700 ml-1 mt-2 font-medium">
              Please choose a unique field title and select a field type from
              the list
            </span>
          )}
        </div>
      )}
      {!add && (
        <button type="button" className="mt-2" onClick={() => showAdd(true)}>
          <AiFillPlusCircle size={30} />
        </button>
      )}
    </div>
  );
};

export default RepeaterField;
