import TextInput from "../../Inputs/TextInput";
import RegisterStepTitle from "../RegisterStepTitle";
import RegistrationFieldHolder from "../RegistrationFieldHolder";

const PersonalDetails: React.FC<any> = ({ register, control, errors }) => {
  return (
    <div>
      <RegisterStepTitle
        title="Personal Details"
        subtitle="We need a few more details about you and your role within the organization. Fields marked with * are mandatory."
      />

      <div className="grid grid-flow-col grid-cols-2 gap-8 mb-6">
        <RegistrationFieldHolder
          {...{
            control,
            errors,
            label: "First Name",
            id: "firstname",
            required: true,
          }}
          field={({ field: { onChange, value } }: any): JSX.Element => (
            <TextInput
              id="firstname"
              value={value}
              onChange={onChange}
              invalid={!!errors.firstname}
              placeholder="John"
            />
          )}
        />

        <RegistrationFieldHolder
          {...{
            control,
            errors,
            label: "Surname",
            id: "surname",
            required: true,
          }}
          field={({ field: { onChange, value } }: any): JSX.Element => (
            <TextInput
              value={value}
              onChange={onChange}
              invalid={!!errors.surname}
              placeholder="Doe"
            />
          )}
        />
      </div>
      <div className="grid grid-flow-col grid-cols-2 gap-8 mb-6">
        <RegistrationFieldHolder
          {...{
            control,
            errors,
            label: "Contact Number",
            id: "phone_number",
            required: true,
          }}
          field={({ field: { onChange, value } }: any): JSX.Element => (
            <TextInput
              value={value}
              onChange={onChange}
              invalid={!!errors.phone_number}
              placeholder="+44 123 456 7890"
            />
          )}
        />

        <RegistrationFieldHolder
          {...{
            control,
            errors,
            label: "Role",
            id: "role",
            required: true,
          }}
          field={({ field: { onChange, value } }: any): JSX.Element => (
            <TextInput
              value={value}
              onChange={onChange}
              invalid={!!errors.role}
              placeholder="Enter your role"
            />
          )}
        />
      </div>
    </div>
  );
};

export default PersonalDetails;
