import { useNavigate } from "react-router-dom";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import React, { useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Form from "../../Form";
import FormFieldErrors from "../../Form/FormFieldErrors";
import TextInput from "../../Inputs/TextInput";
import SettingsPageHeader from "../../SettingsPartsReUse/Header";
import axios from "../../../plugins/axios";
import WysiwygInput from "../../Inputs/Wysiwyg";

const SingleDocumentEditScreen: React.FC<any> = ({
  match: {
    params: { documentId },
  },
}) => {
  const navigate = useNavigate();

  const isNew = documentId === "create";

  const [document, setDocument] = useState<any>(undefined);

  const [loading, setLoading] = useState(true);

  const getDocument = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await axios.get(`/manufacturers/documents/${documentId}`);

      try {
      } catch (error) {}
      setDocument(data);
    } catch (error) {
      navigate("/settings/policies-and-terms");
    }
    setLoading(false);
  }, [documentId, navigate]);

  useEffect(() => {
    if (documentId === "create") {
      setDocument({
        id: undefined,
        title: "",
        content: convertToRaw(
          EditorState.createWithContent(
            ContentState.createFromText("This is the content")
          ).getCurrentContent()
        ),
      });
      setLoading(false);
    } else {
      getDocument();
    }
  }, [documentId, getDocument]);

  const onSubmit = async ({ data: { id } }: any) => {
    navigate(`/settings/policies-and-terms/${id}`);
  };

  const getDefaults = () => document;
  if ((!isNew && !document) || loading) return <span>Loading...</span>;

  return (
    <div>
      <SettingsPageHeader
        title={isNew ? "Create Document" : "Edit Document"}
        subtitle={
          isNew
            ? "Fill out the document bellow to create a new one"
            : "Update document details here or delete"
        }
      />

      <Form
        getDefaults={getDefaults}
        onCompleted={onSubmit}
        buttonTitle="Save document"
        endpoint={
          isNew
            ? "manufacturers/documents"
            : `manufacturers/documents/${documentId}`
        }
        method={isNew ? "post" : "put"}
        className="max-w-5xl mx-auto my-6"
        formButtonHolderClassName="w-full md:w-1/2"
        render={({ errors, control }) => (
          <>
            <div className="mb-4 mt-6">
              <Controller
                name="title"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }: any): JSX.Element => (
                  <TextInput
                    value={value}
                    onChange={onChange}
                    invalid={!!errors.title}
                    placeholder="Document title"
                  />
                )}
              />

              <FormFieldErrors
                errors={errors}
                field="title"
                requiredMessage="Document title is required"
              />
            </div>

            <div className="mb-4 mt-6">
              <Controller
                name="content"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }: any): JSX.Element => (
                  <WysiwygInput
                    value={value}
                    onChange={onChange}
                    invalid={!!errors.content}
                  />
                )}
              />

              <FormFieldErrors
                errors={errors}
                field="content"
                requiredMessage="Document content is required"
              />
            </div>
          </>
        )}
      />
    </div>
  );
};

export default SingleDocumentEditScreen;
