import HeaderLink from "./HeaderLink";

interface DashboardHeaderProps {
  active?: string;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ active = "" }) => {
  const newOrders = 0;
  const newReviews = 0;

  return (
    <div className="border-b h-20 border-gray-200 p-2 flex items-center justify-center">
      <HeaderLink title="Dashboard" path="/dashboard" active={active} />
      <HeaderLink
        title="Orders"
        path="/dashboard/orders"
        active={active}
        badgeCount={newOrders}
      />
      <HeaderLink
        title="Reviews"
        path="/dashboard/reviews"
        active={active}
        badgeCount={newReviews}
      />
    </div>
  );
};

export default DashboardHeader;
