import { useForm } from "react-hook-form";
import { GrFormPreviousLink } from "react-icons/gr";
import { useEffect, useState } from "react";
import axios from "axios";
import DefaultButton from "../Buttons/Default";
import PersonalDetails from "./RegisterSteps/PersonalDetails";
import { registrationSteps } from "./register.utils";
import CompanyDetails from "./RegisterSteps/CompanyDetails";
import Location from "./RegisterSteps/Location";
import Team from "./RegisterSteps/Team";
import Industry from "./RegisterSteps/Industry";
import Certifications from "./RegisterSteps/Certifications";
import TermsAndPrivacy from "./RegisterSteps/TermsAndPrivacy";

const CreateAccount: React.FC<any> = ({
  setType,
  setRegisterStep,
  registerStep,
  registrationState,
  registrationDispatch,
}) => {
  const [industries, setIndustries] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    getIndustriesList();
  }, []);

  const onSubmit = async (form: any) => {
    setLoading(true);
    try {
      const { data } = await axios.post("manufacturers", form); // eslint-disable-line
      setType("register-success");
    } catch (error) {}
    setLoading(false);
  };

  const formProps = (): object => {
    return {
      errors,
      register,
      control,
      getValues,
      setValue,
      industries,
      watch,
    };
  };

  const getNextStep = (): string => {
    const curr = registrationSteps.findIndex(
      (step) => step.id === registerStep
    );
    return registrationSteps[curr + 1].id;
  };

  const getPreviousStep = (): string => {
    const curr = registrationSteps.findIndex(
      (step) => step.id === registerStep
    );
    return registrationSteps[curr - 1].id;
  };

  const getIndustriesList = async () => {
    try {
      const {
        data: { data },
      } = await axios.get("/industries?with_rules=1");

      setIndustries(data);
    } catch (error) {}
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-4xl mx-auto overflow-auto h-screen pb-20 p-2"
    >
      <div>
        {registerStep === "personal_details" && (
          <PersonalDetails {...formProps()} />
        )}
        {registerStep === "company_details" && (
          <CompanyDetails {...formProps()} />
        )}
        {registerStep === "location" && <Location {...formProps()} />}
        {registerStep === "team" && <Team {...formProps()} />}
        {registerStep === "industry" && <Industry {...formProps()} />}
        {registerStep === "certifications" && (
          <Certifications {...formProps()} />
        )}
        {registerStep === "terms" && <TermsAndPrivacy {...formProps()} />}
      </div>
      <div className="flex justify-between items-center mt-10">
        {registerStep === "personal_details" ? (
          <div />
        ) : (
          <div
            onClick={() => setRegisterStep(getPreviousStep())}
            className="text-sm text-gray-500 cursor-pointer flex items-center justify-center"
          >
            <GrFormPreviousLink className="inline text-xl" /> Previous Step
          </div>
        )}
        <div className="w-32">
          {registerStep !== "terms" ? (
            <DefaultButton
              title="Next"
              type="button"
              onClick={async () => {
                const registerStepData = registrationSteps.find(
                  (ri) => ri.id === registerStep
                );
                if (!registerStepData) return;
                const result = await trigger();
                if (result) {
                  await registrationDispatch({
                    type: "removeInvalid",
                    payload: registerStep,
                  });
                  await registrationDispatch({
                    type: "setValid",
                    payload: registerStep,
                  });

                  setRegisterStep(getNextStep());
                } else {
                  await registrationDispatch({
                    type: "removeValid",
                    payload: registerStep,
                  });
                  await registrationDispatch({
                    type: "setInvalid",
                    payload: registerStep,
                  });
                }
              }}
            />
          ) : (
            <DefaultButton
              title={loading ? "Loading" : "Submit"}
              type="submit"
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default CreateAccount;
