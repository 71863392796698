import { useMemo } from "react";

interface BadgeProps {
  count: number;
  showCount?: boolean;
}
const Badge: React.FC<BadgeProps> = ({
  children,
  count,
  showCount = false,
}) => {
  const actualCount = useMemo<string>(() => {
    if (count === 0) return "";

    if (count > 10) return "10+";
    return count.toString();
  }, [count]);

  return (
    <div className="relative">
      {count > 0 && showCount && <span>{actualCount}asdf</span>}
      {count > 0 && !showCount && (
        <span className="w-2 h-2 absolute -top-1 -right-1 bg-red-600 block rounded-full" />
      )}

      {children}
    </div>
  );
};

export default Badge;
