import { useReducer, useState } from "react";
import ChooseAccount from "./ChooseAccount";
import CreateAccount from "./CreateAccount";
import JoinAccountScreen from "./JoinAccount";
import { registerReducer, registerInitialState } from "./register.utils";
import RegistrationSuccessful from "./RegistrationSuccessful";
import NoAccountSidebar from "./Sidebar";

const NoAccountScreen = () => {
  const [type, setType] = useState("pick");
  const [registerStep, setRegisterStep] = useState("personal_details");

  const [registrationState, registrationDispatch] = useReducer(
    registerReducer,
    registerInitialState
  );

  return (
    <div className="flex w-full bg-gray-100 h-full fixed">
      <NoAccountSidebar
        {...{
          type,
          registerStep,
          setRegisterStep,
          registrationState,
          registrationDispatch,
        }}
      />

      <div className="w-full p-8">
        <div className="w-full flex items-end justify-end max-w-4xl mx-auto">
          Having Trouble?{" "}
          <a
            href="https://remademarket.com/faqs"
            className="text-green-600 font-medium ml-1 cursor-pointer hover:text-green-700"
          >
            Get Help
          </a>{" "}
        </div>
        {type === "pick" && <ChooseAccount setType={setType} />}
        {type === "create" && (
          <CreateAccount
            {...{
              setType,
              setRegisterStep,
              registerStep,
              registrationState,
              registrationDispatch,
            }}
          />
        )}
        {type === "join" && (
          <JoinAccountScreen
            {...{
              setType,
              setRegisterStep,
              registerStep,
              registrationState,
              registrationDispatch,
            }}
          />
        )}
        {type === "register-success" && (
          <RegistrationSuccessful setType={setType} />
        )}
      </div>
    </div>
  );
};

export default NoAccountScreen;
