import axios, { Method } from "axios";
import { useState } from "react";
import { Control, useForm, UseFormReset } from "react-hook-form";
import DefaultButton from "../Buttons/Default";
import { errorParser } from "./Form.utils";

interface FormRenderProps {
  reset: UseFormReset<any>;
  control: Control<any, object>;
  errors: any;
}
interface FormProps {
  getDefaults?: any;
  onCompleted: any;
  className?: string;
  render: (props: FormRenderProps) => any;
  buttonTitle?: string;
  formButtonHolderClassName?: string;
  endpoint: string;
  method?: Method;
  resetProps?: object;
  transform?: (data: object) => object;
}

const Form: React.FC<FormProps> = ({
  getDefaults = () => {},
  onCompleted,
  className = "",
  render,
  buttonTitle = "Submit",
  formButtonHolderClassName = "w-3/12",
  endpoint,
  method = "post",
  resetProps = { keepValues: true },
  transform = (data) => data,
}) => {
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    control,
    setError,
  } = useForm({
    defaultValues: getDefaults(),
  });

  const onFormSubmit = async (formData: any) => {
    setLoading(true);
    try {
      const { data } = await axios({
        method,
        url: endpoint,
        data: transform(formData),
      });
      await onCompleted(data);
      reset(getDefaults(), resetProps);
    } catch (error) {
      errorParser(error, setError);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className={className}>
      {render({ reset, control, errors })}

      <div className="flex items-center">
        <div className={formButtonHolderClassName}>
          <DefaultButton
            type="submit"
            title={loading ? "Loading..." : buttonTitle}
            disabled={!isDirty}
          />
        </div>
        {isDirty && (
          <div
            className="ml-2 pl-3 pr-3 cursor-pointer text-gray-600 hover:text-black"
            onClick={() => reset(getDefaults())}
          >
            Reset
          </div>
        )}
      </div>
    </form>
  );
};

export default Form;
