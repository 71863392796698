import React from "react";

interface ScreenProps {
  schema: any;
  values: any;
}

const FormBuilderDisplay: React.FC<ScreenProps> = ({ schema, values }) => {
  const renderValue = (
    type: "text" | "files" | "file" | "textarea",
    key: string
  ) => {
    if (!values[key]) return "Not provided";

    switch (type) {
      case "text":
      case "textarea":
        return values[key];

      case "files":
        return (
          <div>
            {values[key].map((val: any, index: number) => (
              <a
                href={val.url}
                target="_blank"
                rel="noreferrer"
                download
                className="cursor-pointer text-red-700"
              >
                {val.path.split("/")[val.path.split("/").length - 1]}
              </a>
            ))}
          </div>
        );
      default:
        break;
    }
    return "";
  };
  return (
    <div>
      {schema &&
        schema.map((field: any, key: number) => (
          <div key={key} className="border-b pb-3 mb-3">
            <h3 className="font-medium text-gray-600 text-lg">{field.label}</h3>
            <div>{renderValue(field.type, field.id)}</div>
          </div>
        ))}
    </div>
  );
};

export default FormBuilderDisplay;
